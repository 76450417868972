import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable } from 'rxjs';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';

@Injectable()
export class FooterLinkContentService {

    public apiService: ApiService;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
    }

    public getFooterLinkContent(path, footerObject): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['footer']['content'] + path + footerObject,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }
}