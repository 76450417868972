export class UpdateProfile {
    id: any;
    firstName: string;
    lastName: string;
    emailId: string;
    dateOfBirth: any;
    fileName: string;
    fileType: string;
    filePath: string;
}

export class UpdateProfileResponse {
    success: boolean;
    message: string
    data: any;
}