import { Pipe, PipeTransform } from '@angular/core';
import { PageData } from '@home/model/pagecategory.model';
import { carouselData } from '@home/model/carousal';

@Pipe({ name: 'gotoCarousel' })
export class GotoCarousel implements PipeTransform {
    transform(pagedata: PageData, carousel: carouselData): string {
        if (pagedata != undefined) {
            return pagedata.clickThrough;
        }
    }
}   