import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProfileComponent } from '../component/edit-profile.component';
import { AppRoutingModule } from '@app/module/app.routing.module';
import { EditProfileService } from '../service/edit-profile.service';
import { BaseModule } from '@base/module/base.module';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [EditProfileComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    BaseModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule
  ],
  exports: [EditProfileComponent],
  providers: [EditProfileService]
})
export class EditProfileModule { }
