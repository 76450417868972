export class Carousal {
    device_type: string;
    path: string;
    type: string;
}

export class CurousalResponse {
    success: boolean;
    message: string;
    data: carouselData[];
}

export class carouselData {
    // device: string;
    // same_for_all_orientation: boolean;
    // path: string;
    // type: string;
    // alias: string;
    // orientation: Orientations[];
    path: string;
    title: string;
    description: string;
    isActive: any;
    exclusionText: string;
    sameForAllZone: any;
    displayIndexing: string;
    sameForAllProductType: any;
    cdlmId: any;
    fileName: string;
    filePath: string;
    dtypeId: any;
    langId: any;
    isVisible: any;
    isPrimaryNavigationVisible: any;
    isMenuCategoryVisible: any;
    isAssetGroupVisible: any;
    isAssetCategoryVisible: any;
    isPageCategoryVisible: any;
    label: string;
    isButtonRequired: any;
    clickThrough: string;
    confDisplayIndexing: any;
    caption: string;
    carouselType: string;
    confPrimaryNavigation: any;
    confMenuCategory: string;
    confAssetGroup: string;
    confAssetCategory: string;
    confAsset: string;
    confTrailerVideo: string;
    uploadImage: any;
    playbackUrl: string;
    type: string;
}


export class Orientations {
    // name: string;
    // caption: any;
    // is_button: string;
    // clickthrough: any;
    // display_indexing: string;
    // title: string;
    // label: any;
    // description: any;
    // is_visible: boolean;
    // carousal_type: string;
    // image: string;
}