import { FooterService } from '@footer/services/footer.service';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { Router } from '@angular/router';
import { ProfileService } from '@profile/service/profile.service';
import { ServiceLocator } from '@base/constants/service-locator';
import {
  ChangePassword,
  GetWatchlistData,
  GetfavouriteData,
  AllData,
  ProfileData
} from '@profile/model/profile.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HeaderService } from 'src/app/components/header/header.service';
import { PageData } from '@home/model/pagecategory.model';
import { VideoPage } from '@video-page/asset-page.model';
import { VideoPageService } from '@video-page/asset-page.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthServiceConfig } from '@auth/service/auth.service.config';

declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: '../view/profile.component.html',
  styleUrls: ['../css/profile.component.css']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  public profileService: ProfileService;
  public profile: ProfileData;
  public pass: any;
  public newpass: any;
  headerService: HeaderService;
  watchlistData: GetWatchlistData;
  favouriteData: GetfavouriteData;
  videopageData: VideoPage;
  public videopageService: VideoPageService;
  removeTofavouriteData: AllData;
  removeToWatchListData: AllData;
  orderListingData = [];

  isLoading: boolean = false
  //#cambios message cancel subs
  public txtCancelSubs: String;


  public profilePasswordForm: FormGroup = new FormGroup(
    {
      password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required, Validators.pattern(
        "(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}")]),
      confirm_new_password: new FormControl('', [Validators.required])
    },
    this.passwordMatchValidator
  );

  public passwordMatchValidator(g: FormGroup) {
    return g.get('new_password').value === g.get('confirm_new_password').value
      ? null
      : { mismatch: true };
  }

  async ngOnInit() {
    await this.waitForToken();

    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    window.scroll(0, 0);

    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    $('.seasons-list li').click(function () {
      $('.seasons-list li').removeClass('activeSeasons');
      $(this).addClass('activeSeasons');
    });

    this.profileService = ServiceLocator.injector.get(ProfileService);
    this.getfavouritAndWatchlist();
    this.getProfile();
    this.getOrderListing();
    this.headerService.reloadHeader.emit(true);
  }

  constructor(public router: Router,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    private footerService: FooterService,
    private oauthService: OAuthService) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
  }

  public menuClick() {
    $('.tablist ul li a').click(function () {
      $('.tablist ul li').removeClass('active');
      $(this)
        .parent('li')
        .addClass('active');

      var active_div = $(this).attr('dtat-target');
      $('.tabing').hide();
      $('#' + active_div).show();
    });

    $('.seasons-list li').click(function () {
      $('.seasons-list li').removeClass('activeSeasons');
      $(this).addClass('activeSeasons');
    });
  }
  public gotoEditProfile(url): void {
    const myurl = `${url}`;
    this.router.navigateByUrl(myurl);
  }
  public async signOut(): Promise<void> {
    this.oauthService.configure(new AuthServiceConfig());
    await this.oauthService.loadDiscoveryDocument();
    this.oauthService.redirectUri = location.origin + '/home'

    this.isLoading = true;

    this.headerService.userLogout().subscribe(logoutres => {
      if (logoutres !== undefined) {
        this.headerService.getToken().subscribe(
          async res => {
            if (res !== undefined) {
              BaseComponent.clearAuthData();
              BaseComponent.setGuestToken(res);
              this.isLoading = false;
              this.oauthService.logOut(false);
            }
          },
          err => {
            console.log(err);
            this.isLoading = false;
          }
        );
      }
    });
  }

  public getProfile(): void {
    this.profileService.getUserProfile(this.userId).subscribe(
      res => {
        if (res !== undefined) {
          this.profile = res.data[0];
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public changePassword(profilePasswordForm: FormGroup): void {
    const changepass = new ChangePassword();
    changepass.emailId = this.user.emailId
    changepass.password = this.profilePasswordForm.value['password'];
    changepass.newPassword = this.profilePasswordForm.value['new_password'];
    if (changepass.password !== changepass.newPassword) {
      this.profileService.createNewPassword(changepass).subscribe(
        res => {
          if (res !== undefined) {
            if (res.success == true) {
              this.successResponse(res.message);
              this.profilePasswordForm.reset();
              $('#changePass').modal('hide');
            } else {
              this.errMessageResponse(res.message);
            }
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse('La contraseña anterior y la nueva, deben ser distintas');
    }
  }


  public getfavouritAndWatchlist(): void {
    const requestWatchlistData = {
      "deviceTypeId": "1"
    };

    // const requestFavouriteData = {
    //   "userId": this.getUserId()
    // };

    let fiterWatchlistString = "?filter=" + encodeURI(JSON.stringify(requestWatchlistData));
    // let fiterFavouriteString = "?filter="+encodeURI(JSON.stringify(requestFavouriteData)); 
    this.profileService.getWatchlist(fiterWatchlistString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          res.data.forEach(asset => {
            // Set "streamsmas-content" in img paths
            asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
            asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
            asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
            asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

            // Set default duration if not populated
            if (asset.bookmarkDuration && !asset.duration) {
              asset.duration = 7200;
              asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
            }
          });
          this.watchlistData = res;
        } else if (res !== undefined && res.success === false) {
        }
      },
      err => {
        console.log(err);
      }
    );

    this.profileService.getfavourite().subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          res.data.data.forEach(asset => {
            // Set "streamsmas-content" in img paths
            asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
            asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
            asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
            asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

            // Set default duration if not populated
            if (asset.bookmarkDuration && !asset.duration) {
              asset.duration = 7200;
              asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
            }
          });
          this.favouriteData = res.data;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getOrderListing() {
    // const orderListingData = {
    //   "userId": this.getUserId()
    // };

    // let fiterWatchlistString = "?filter="+encodeURI(JSON.stringify(orderListingData));

    this.profileService.getOrderListing().subscribe(
      res => {
        if (res != undefined && res.success == true) {
          this.orderListingData = res.data.data
        }
      })
  }

  public gotoVideoPage(pagedata: PageData): void {
    // if (pagedata.type === 'asset') {
    // this.router.navigate(['/video-page'], {
    //   queryParams: { path: pagedata.path }
    // });
    this.router.navigate(['/video', pagedata.title, pagedata.path]);
    // } else if (pagedata.type === 'asset_group') {
    //   this.router.navigate(['/season', pagedata.path]);
    // }
  }

  public downloadInvoice(invoice) {
    const invoiceRequest = {
      invoiceId: invoice.orderId
    };
    let fiterString = "?filter=" + encodeURI(JSON.stringify(invoiceRequest));
    this.profileService.downloadInvoice(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          console.log(res);
          // pdf download start
          const linkSource = 'data:application/pdf;base64,' + res.data;
          const downloadLink = document.createElement("a");
          const fileName = "sample.pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          // pdf download end
          // imageConvert start
          // var a = document.createElement("a"); //Create <a>
          // a.href = "data:image/png;base64," + res.data; //Image Base64 Goes here
          // a.download = "Image.png"; //File name Here
          // a.click(); 
          // imageConvert end
        }
      })
  }

  public removeTofavourite(assetItem: AllData): void {
    let index: any;
    const pageRequest = {
      assetId: assetItem.path
    };
    this.videopageService.removeTofavourite(pageRequest).subscribe(
      res => {
        if (res !== undefined) {
          this.successResponse(res.message);
          index = this.favouriteData.data.indexOf(assetItem);
          this.favouriteData.data.splice(index, 1);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public setremoveTofavouriteData(e: Event, setdata: AllData, modal: string): void {
    e.stopPropagation();
    this.removeTofavouriteData = setdata;
    $(modal).modal('show');
  }

  public removeToWatchList(assetItem: AllData): void {
    let index: any;
    const pageRequest = {
      assetId: assetItem.path,
      userId: this.profile.id,
      // profileId:this.profile.id
    };
    this.videopageService.removeToWatchList(pageRequest).subscribe(
      res => {
        if (res !== undefined) {
          this.successResponse(res.message);
          index = this.watchlistData.data.indexOf(assetItem);
          this.watchlistData.data.splice(index, 1);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public setremoveToWatchListData(setdata: AllData): void {
    this.removeToWatchListData = setdata;
  }

  public cancelSubscription() {

    var form = new FormData();
    form.append("tenant", this.tenant);
    form.append("userid", String(this.profile.id));

    var settings = {
      "url": "https://api.oneplay.net/processes/stopsubscription/",
      "method": "POST",
      "timeout": 0,
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
      "data": form
    };

    $.ajax(settings).done(function (response) {
      var res = JSON.parse(response);
      this.txtCancelSubs = res.text_message
      $('#pCancelSubs').text(this.txtCancelSubs);
      $('#cancel-subscription-message').modal('show');
    });
  }
}
