import { Component } from '@angular/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthServiceConfig } from '../service/auth.service.config';
import { BaseComponent } from '@base/component/base.component';
import { decodeJWT } from 'src/app/api/api';

@Component({
  selector: 'login_redirect',
  templateUrl: '../view/auth.component.html',
  styleUrls: ['../css/auth.component.css']
})
export class AuthComponent extends BaseComponent{
  
  constructor(private oauthService: OAuthService) {
    super();
    this.configureOAuthService();
  }

  private async configureOAuthService() {
    this.oauthService.configure(new AuthServiceConfig());
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();

    const accessToken = this.oauthService.getAccessToken();
    const data = decodeJWT(accessToken);

    if (data.login_error) {
      this.oauthService.redirectUri = location.origin + '/home?error=' + data.login_error;
      this.oauthService.logOut(false);
    } else if (data.token_login && data.preferred_username) {
      const login_token = data.token_login;
      const emailId = decodeJWT(data.token_login).data.email;
      const id = decodeJWT(data.token_login).data.userId;
      const user = {id, emailId}

      BaseComponent.setUserToken(login_token);
      BaseComponent.setUser(user);
      BaseComponent.fillAuthData();

      this.router.navigate(['']);
    } else {
      this.oauthService.redirectUri = location.origin + '/home?error=Error%20inesperado';
      this.oauthService.logOut(false);;
    }
  }
}