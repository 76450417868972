import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { BaseComponent } from '@base/component/base.component';
import { VideoPageService } from '@video-page/asset-page.service';
import { ServiceLocator } from '@base/constants/service-locator';
import CryptoJS from 'crypto-js';
import { DOCUMENT } from '@angular/common';
import { loadStripe } from '@stripe/stripe-js';


declare var $: any;

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent extends BaseComponent implements OnInit {
  public videopageService: VideoPageService;
  order: any;
  returnUrl = '';
  username = '';
  _router: Router;
  userProfileData: any;
  orderList: any = [];
  subscriptionpackagetypeList: any[] = [];
  subscriptionpackagetypeListPremium: any[] = [];
  handler: any;
  errorExistingPlanMessage: string;
  errorSelectPlanMessage: string;
  subscriptionName: string;
  subscriptionId: any;
  anyActivePlan = false;
  activeSubscriptionId = 0;
  selectedplan: any;

  constructor(router: Router, private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
    this._router = router;
  }

  async ngOnInit() {
    await this.waitForToken();

    this.getSubscriptionPlans();
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    $('.transperent-layout').css('margin-top', '80px');
  }

  getSubscriptionPlans(): void {
    const subscriptionRequest = { deviceTypeId: '1' };
    const subscriptionFiterString = '?filter=' + encodeURI(JSON.stringify(subscriptionRequest));
    this.videopageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
      if (res != undefined) {
        if (res.success && res.data.data.length > 0) {
          const requestParameters = {
            userId: this.user.id
          };
          const requestObject = '?filter=' + encodeURI(JSON.stringify(requestParameters));

          this.videopageService.CheckPlanValidity(requestObject).subscribe(response => {
            if (response !== undefined && response.success) {
              response.data.forEach(element => {
                res.data.data.map((res) => {
                  if (res.path == element.subscriptionId) {
                    res.purchased = true

                  }
                })
              });
            }
          });

          this.subscriptionpackagetypeList = []; //plan basico
          for (let plan of res.data.data) {
            //Se oculta plan de $79,$425 y $799
            if (plan.title != 'Free' && plan.subscriptionType != 'TVOD' && plan.isActive == 1 && plan.rate != '79' && plan.rate != '425' && plan.rate != '799' && plan.rate != '250' && plan.rate != '1350' && plan.rate != '2550') {
              this.subscriptionpackagetypeList.push(plan);
            }
          }
          this.subscriptionpackagetypeListPremium = []; //plan premium
          for (let plan of res.data.data) {
            if (plan.title != 'Free' && plan.subscriptionType != 'TVOD' && plan.isActive == 1 && plan.rate != '79' && plan.rate != '425' && plan.rate != '799' && plan.rate != '100' && plan.rate != '540' && plan.rate != '1020') {
              this.subscriptionpackagetypeListPremium.push(plan);
            }
          }

          //PARA ARREGLAR ERROR DE HTTPS
          // const ipinfoWrapper = new IPinfoWrapper("17ca50b0ba1128");
          // let tokenIP = '17ca50b0ba1128';
          // fetch('https://ipinfo.io', {
          //   headers: {
          //     'Authorization': 'Bearer ' + `${tokenIP}`
          //   }
          // })
          //   .then(response => response.text())
          //   .then(response => {
          //     var jsonDATA = JSON.parse(response);
          //     let ipAddress = jsonDATA.ip;


          //     ipinfoWrapper.lookupIp(ipAddress).then((response: IPinfo) => {
          //       let countryCode = response.countryCode;
          //       if (countryCode == 'UY' || countryCode == 'AR' || countryCode == 'PA' || countryCode == 'NI' || countryCode == 'HN' || countryCode == 'GT' || countryCode == 'SV' || countryCode == 'CR') {
          //         this.subscriptionpackagetypeList = []; //plan basico
          //         for (let plan of res.data.data) {
          //           //Se oculta plan de $79,$425 y $799
          //           if (plan.title != 'Free' && plan.subscriptionType != 'TVOD' && plan.isActive == 1 && plan.rate != '79' && plan.rate != '425' && plan.rate != '799' && plan.rate != '250' && plan.rate != '1350' && plan.rate != '2550') {
          //             this.subscriptionpackagetypeList.push(plan);
          //           }
          //         }
          //         this.subscriptionpackagetypeListPremium = []; //plan premium
          //         for (let plan of res.data.data) {
          //           if (plan.title != 'Free' && plan.subscriptionType != 'TVOD' && plan.isActive == 1 && plan.rate != '79' && plan.rate != '425' && plan.rate != '799' && plan.rate != '100' && plan.rate != '540' && plan.rate != '1020') {
          //             this.subscriptionpackagetypeListPremium.push(plan);
          //           }
          //         }
          //       } else {
          //         this.subscriptionpackagetypeListPremium = []; //plan premium
          //         for (let plan of res.data.data) {
          //           if (plan.title != 'Free' && plan.subscriptionType != 'TVOD' && plan.isActive == 1 && plan.rate != '79' && plan.rate != '425' && plan.rate != '799' && plan.rate != '100' && plan.rate != '540' && plan.rate != '1020') {
          //             this.subscriptionpackagetypeListPremium.push(plan);
          //           }
          //         }
          //       }
          //       //alert(response.countryCode);
          //       //alert(response.ip);
          //       //alert(response.country);
          //     });
          //   })
          //   .catch(error => console.log('error', error));
        }
      }
    });
  }

  goRent(selectedplan, event) {
    if (this.isLoggedIn == true) {
      this.selectedplan = selectedplan
      $('#payment-model').modal('show');
      this.checkout3(selectedplan, event);
    } else {
      localStorage.setItem('videoUrl', this.router.url)
      this.warningMessageResponse('Por favor Inicia sesión');
    }
  }

  paymentmethod(event, type) {
    if (type == 'stripe') {
      this.checkout(this.selectedplan, event)
    } else if (type == 'voucher') {
      $('#modal-vouchers').modal('show');
      this.checkout2(this.selectedplan, event)
    }
  }

  paymentmethodMercadopago(event, type) {
    if (type == 'mercadopago') {
      this.checkout4(this.selectedplan, event);
    }
  }

  paymentmethodStripeCupon(event, type) {
    if (type == 'cupon') {
      this.checkout4(this.selectedplan, event);
    }
  }

  paymentmethodStripe(event, type) {
    if (type == 'stripe') {
      this.checkout5(this.selectedplan, event);
    }
  }

  navigateback() {
    var redirectUrl = localStorage.getItem('planurl');
    if (redirectUrl == undefined) {
      this.router.navigate(['home']);
    } else {
      this.router.navigate([redirectUrl]);
    }
  }
  checkout(item, e) {
    this.handler = (<any>window).StripeCheckout.configure(
      {
        key: environment.stripeKey,
        locale: "auto",
        token: (token: any) => {
          let cardToken = token.id;
          const orderReq = {
            orderId: cardToken,
            userId: this.user.id,
            subscriptionId: item.path,
            isRecurring: 1,
            billingName: this.user.firstName,
            billingEmail: this.user.emailId,
            status: "Active",
            usageType: "Paid",
            isActive: 1,
            paymentMethod: "stripe"
          };
          this.videopageService.placeOrder(orderReq).subscribe(
            res => {
              if (res !== undefined && res.success === true) {
                this.successResponse(res.message);
                $('#favouriteloader').css({ "display": "none" });
                this.navigateback();
              } else if (res !== undefined && res.success === false) {
                this.errMessageResponse(res.message);
              }
            },
            err => {
              console.log(err);
            }
          );
        },
      }
    );
    let totalAmount = item.rate * 100;
    if (totalAmount > 0) {
      this.handler.open({
        name: "ON STREAM",
        description: item.title,
        amount: totalAmount,
        email: this.user.emailId,
        closed: function () {
          $('#favouriteloader').css({ "display": "none" });
        }
      });
    }
    e.preventDefault();
  }


  checkout2(item, e) {
    let totalAmount = item.rate.toString();
    let totalAmountDecimal = totalAmount.replace(',', '.');
    //en pagina de checkout, al momento de checkout
    //paso 1: le digo a libreria que haga render en el div
    (<any>window).OP.renderFormInElement("voucher-oneplay");
    //paso 2: inicio intent de redeem y paso parametros obligatorios
    (<any>window).OP.openRedeemVoucherIntent({
      client_email: this.user.emailId,
      client_country: this.user.countryCode, //this is the value of the client`s registration on database 
      asset_value: totalAmountDecimal,
      asset_id: item.path, //this code could be either asset id value or the monetization plan id value
      client_id: this.user.id,
      client_platform: "web", //this is identify the platform, values: web,ios,android
    })
      .then(res => {
        const orderReq2 = {
          orderId: res.data.hash,
          userId: this.user.id,
          subscriptionId: item.path,
          isRecurring: 1,
          billingName: this.user.firstName,
          billingEmail: this.user.emailId,
          status: "Active",
          usageType: "Paid",
          isActive: 1,
          amount: item.rate,
          description: item.title,
          paymentMethod: "vouchers"
        };
        let generated_hash = CryptoJS.SHA256(environment.voucherkey + item.path + this.user.id + totalAmountDecimal).toString(CryptoJS.enc.Hex);
        if (generated_hash == res.data.hash) {
          this.videopageService.placeOrder(orderReq2).subscribe(
            apires => {
              if (apires !== undefined && apires.success === true) {
                this.successResponse(res.message);
                $('#favouriteloader').css({ "display": "none" });
                this.navigateback();
              } else if (apires !== undefined && apires.success === false) {
                this.errMessageResponse(apires.message);
              }
            },
            err => {
              console.log(err);
            });
        }
        $('#modal-vouchers').modal('hide');
      })
      .catch(err => {
        $('#modal-vouchers').modal('hide');
        this.errMessageResponse(err.message)
      })
    $('#modal-vouchers').modal('show');
    e.preventDefault();
  }



  checkout3(item, e) {

    let elementoLink = this.document.getElementById('modal-mercadopago');
    let scriptLink = this.rendrer.createElement('script');
    scriptLink.type = 'application/javascript';
    scriptLink.src = 'https://sdk.mercadopago.com/js/v2';
    scriptLink.setAttribute('csp', 'Content-Security-Policy: frame-ancestors "self" https://www.mercadopago.com.uy');
    this.rendrer.appendChild(elementoLink, scriptLink);
  }


  public gotoPlans(): void {

    var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');

    var tenant_hidden = this.tenant;
    var payment_type_hidden = 'SUB';
    var url_path_hidden = 'https://live.onstream.com.ar/';
    var client_id_hidden = this.user.id;
    var title_hidden = 'sidebar';
    var dni_hidden = dni[0];
    var unit_price_hidden = 100;

    var html = '<form action="https://onstream.com.ar/payment-subscription" method="post" id="formHiddenPlans" style="display:none;"><input type="hidden" id="tenant_hidden" name="tenant_hidden" value="' + tenant_hidden + '"><input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="' + payment_type_hidden + '"><input type="hidden" id="url_path_hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" id="client_id_hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" id="title_hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" id="dni_hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" id="unit_price_hidden" name="unit_price_hidden" value="' + unit_price_hidden + '"><button type="submit">ADQUIRIR</button></form>';
    $("#plansFormContainer").append(html);
    setTimeout(function () {
      $("#formHiddenPlans").submit();
    }, 300);
  }



  checkout4(item, e) {
    let totalAmount_mp = item.rate.toString();

    var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');

    var tenant_hidden = this.tenant;
    var payment_type_hidden = 'SUS';
    var url_path_hidden = 'https://live.onstream.com.ar/plans';
    var client_id_hidden = this.user.id;
    var title_hidden = item.title;
    var dni_hidden = dni[0];
    var unit_price_hidden = totalAmount_mp;

    if (totalAmount_mp == '100') {
      var priceId = 'price_1LQvSRBQezDfn9tgyet0zo2a';
    } else if (totalAmount_mp == '540') {
      var priceId = 'price_1LRIZgBQezDfn9tgfs2mjhzD';
    } else if (totalAmount_mp == '1020') {
      var priceId = 'price_1LRIbKBQezDfn9tgM8DLTzzh';
    } else if (totalAmount_mp == '250') {
      var priceId = 'price_1LQvSHBQezDfn9tgAUttmtJO';
    } else if (totalAmount_mp == '1350') {
      var priceId = 'price_1LRIcxBQezDfn9tg3vmY1Jtt';
    } else if (totalAmount_mp == '2550') {
      var priceId = 'price_1LRIfqBQezDfn9tgU30KHLOU';
    }


    var html = '<form action="https://onstream.com.ar/payment-live" method="post" id="formHidden" style="display:none;"><input type="hidden" id="tenant_hidden" name="tenant_hidden" value="' + tenant_hidden + '"><input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="' + payment_type_hidden + '"><input type="hidden" id="url_path_hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" id="client_id_hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" id="title_hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" id="dni_hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" id="unit_price_hidden" name="unit_price_hidden" value="' + unit_price_hidden + '"><input type="hidden" id="plan_price" name="plan_price" value="' + priceId + '"><button type="submit">TARJETA</button></form>';
    $("#container-mercadopagobtn").append(html);
    setTimeout(function () {
      $("#formHidden").submit();
    }, 300);

    e.preventDefault();
  }


  closeloader() {
    $('#favouriteloader').css({ "display": "none" });
  }

  selectedTabClick($event) {
    console.log($event.tab.elRef);
  }

  async checkout5(item, e) {
    var stripePromise = loadStripe('pk_live_51IWT40BQezDfn9tgoWt1qgB1eQ9RsFJvpS6SFeB08H4XSipMBpBuqoesoO93KmreJgWtwkeP9r0fnGawWPaz2SiO00pn1ht1LB'); //clave stripe
    var quantity = 1;
    let totalAmount_mp = item.rate.toString(); //valor de plan seleccionado

    //HTTPS error
    //const ipinfoWrapper = new IPinfoWrapper("17ca50b0ba1128");
    //let tokenIP = '17ca50b0ba1128'; //clave ipinfo
    //fetch('https://ipinfo.io', {
    //   headers: {
    //     'Authorization': 'Bearer ' + `${tokenIP}`
    //   }
    // })
    // .then(response => response.text())
    // .then(response => {
    //   var jsonDATA = JSON.parse(response);
    //   let ipAddress = jsonDATA.ip;

    //guardamos log en base de cuando una persona intenta pagar
    var form = new FormData();
    form.append("team_id", "7");
    form.append("user_id", this.user.id.toString());
    form.append("subscription_id", item.path);
    form.append("name", this.user.firstName);
    form.append("email", this.user.emailId);
    form.append("amount", item.rate.toString());
    form.append("currency", '');
    form.append("url_path", 'https://live.onstream.com.ar/plans');
    //form.append("ip", ipAddress);
    form.append("ip", '');

    var settings = {
      "url": "https://api.oneplay.net/processes/payments/stripe_payment_try.php",
      "method": "POST",
      "timeout": 0,
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
      "data": form
    };

    $.ajax(settings).done(function (response) {
      var res = JSON.parse(response);
      this.txtCancelSubs = res.text_message
      //$('#pCancelSubs').text(this.txtCancelSubs);
      //$('#cancel-subscription-message').modal('show');
    });

    if (totalAmount_mp == '100') {
      var priceId = 'price_1LQvSRBQezDfn9tgyet0zo2a'; //Plan basico mensual
      //Crea session con el plan correspondiente
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        lineItems: [{
          price: priceId,
          quantity: quantity
        }],
        clientReferenceId: this.user.id.toString(),
        customerEmail: this.user.emailId,
        successUrl: 'https://live.onstream.com.ar/plans',
        cancelUrl: 'https://live.onstream.com.ar/plans',
      });
      if (error) {
        alert(error);
        //console.log(error);
      }
    } else if (totalAmount_mp == '540') {
      var priceId = 'price_1LRIZgBQezDfn9tgfs2mjhzD'; //Plan basico semestral
      //Crea session con el plan correspondiente
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        lineItems: [{
          price: priceId,
          quantity: quantity
        }],
        clientReferenceId: this.user.id.toString(),
        customerEmail: this.user.emailId,
        successUrl: 'https://live.onstream.com.ar/plans',
        cancelUrl: 'https://live.onstream.com.ar/plans',
      });
      if (error) {
        alert(error);
        //console.log(error);
      }
    } else if (totalAmount_mp == '1020') {
      var priceId = 'price_1LRIbKBQezDfn9tgM8DLTzzh'; //Plan basico anual
      //Crea session con el plan correspondiente
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        lineItems: [{
          price: priceId,
          quantity: quantity
        }],
        clientReferenceId: this.user.id.toString(),
        customerEmail: this.user.emailId,
        successUrl: 'https://live.onstream.com.ar/plans',
        cancelUrl: 'https://live.onstream.com.ar/plans',
      });
      if (error) {
        alert(error);
        //console.log(error);
      }
    } else if (totalAmount_mp == '250') {
      var priceId = 'price_1LQvSHBQezDfn9tgAUttmtJO'; //Plan premium mensual
      //Crea session con el plan correspondiente
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        lineItems: [{
          price: priceId,
          quantity: quantity
        }],
        clientReferenceId: this.user.id.toString(),
        customerEmail: this.user.emailId,
        successUrl: 'https://live.onstream.com.ar/plans',
        cancelUrl: 'https://live.onstream.com.ar/plans',
      });
      if (error) {
        alert(error);
        //console.log(error);
      }
    } else if (totalAmount_mp == '1350') {
      var priceId = 'price_1LRIcxBQezDfn9tg3vmY1Jtt'; //Plan premium semestral
      //Crea session con el plan correspondiente
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        lineItems: [{
          price: priceId,
          quantity: quantity
        }],
        clientReferenceId: this.user.id.toString(),
        customerEmail: this.user.emailId,
        successUrl: 'https://live.onstream.com.ar/plans',
        cancelUrl: 'https://live.onstream.com.ar/plans',
      });
      if (error) {
        alert(error);
        //console.log(error);
      }
    } else if (totalAmount_mp == '2550') {
      var priceId = 'price_1LRIfqBQezDfn9tgU30KHLOU'; //Plan premium mensual
      //Crea session con el plan correspondiente
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'subscription',
        lineItems: [{
          price: priceId,
          quantity: quantity
        }],
        clientReferenceId: this.user.id.toString(),
        customerEmail: this.user.emailId,
        successUrl: 'https://live.onstream.com.ar/plans',
        cancelUrl: 'https://live.onstream.com.ar/plans',
      });
      if (error) {
        alert(error);
        //console.log(error);
      }
    }



    //HTTPS error
    //Crea instancia de stripe con todos los datos
    //   ipinfoWrapper.lookupIp(ipAddress).then(async (response: IPinfo) => {
    //     let countryCode = response.countryCode;


    //     if (countryCode == 'UY') {
    //       if (totalAmount_mp == '100') {
    //         var priceId = 'price_1LQvSRBQezDfn9tgyet0zo2a'; //Plan basico mensual
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '540') {
    //         var priceId = 'price_1LQvSNBQezDfn9tgPReUe0tD'; //Plan basico semestral
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '1020') {
    //         var priceId = 'price_1LQvSJBQezDfn9tgVHuSyIsK'; //Plan basico anual
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '250') {
    //         var priceId = 'price_1LQvSHBQezDfn9tgAUttmtJO'; //Plan premium mensual
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '1350') {
    //         var priceId = 'price_1LQvSEBQezDfn9tgZx9zNR4m'; //Plan premium semestral
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '2550') {
    //         var priceId = 'price_1LQvSCBQezDfn9tg6JureU17'; //Plan premium mensual
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       }
    //     } else {
    //       if (totalAmount_mp == '250') {
    //         var priceId = 'price_1LQvRzBQezDfn9tgz1nCXJXB'; //Plan USD premium mensual
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '1350') {
    //         var priceId = 'price_1LQvRgBQezDfn9tgZV1wmwfT'; //Plan USD premium semestral
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       } else if (totalAmount_mp == '2550') {
    //         var priceId = 'price_1LQvRKBQezDfn9tglWmgijId'; //Plan USD premium anual
    //         //Crea session con el plan correspondiente
    //         const stripe = await stripePromise;
    //         const { error } = await stripe.redirectToCheckout({
    //           mode: 'subscription',
    //           lineItems: [{
    //             price: priceId,
    //             quantity: quantity
    //           }],
    //           clientReferenceId: this.user.id,
    //           customerEmail: this.user.emailId,
    //           successUrl: 'https://live.onstream.com.ar/plans',
    //           cancelUrl: 'https://live.onstream.com.ar/plans',
    //         });
    //         if (error) {
    //           alert(error);
    //           //console.log(error);
    //         }
    //       }
    //       if (countryCode == 'AR' || countryCode == 'PA' || countryCode == 'NI' || countryCode == 'HN' || countryCode == 'GT' || countryCode == 'SV' || countryCode == 'CR') {
    //         if (totalAmount_mp == '100') {
    //           var priceId = 'price_1LQvS9BQezDfn9tgy1swFBys'; //Plan USD basico mensual
    //           //Crea session con el plan correspondiente
    //           const stripe = await stripePromise;
    //           const { error } = await stripe.redirectToCheckout({
    //             mode: 'subscription',
    //             lineItems: [{
    //               price: priceId,
    //               quantity: quantity
    //             }],
    //             clientReferenceId: this.user.id,
    //             customerEmail: this.user.emailId,
    //             successUrl: 'https://live.onstream.com.ar/plans',
    //             cancelUrl: 'https://live.onstream.com.ar/plans',
    //           });
    //           if (error) {
    //             alert(error);
    //             //console.log(error);
    //           }
    //         } else if (totalAmount_mp == '540') {
    //           var priceId = 'price_1LQvS6BQezDfn9tgznfN3vNP'; //Plan USD basico semestral
    //           //Crea session con el plan correspondiente
    //           const stripe = await stripePromise;
    //           const { error } = await stripe.redirectToCheckout({
    //             mode: 'subscription',
    //             lineItems: [{
    //               price: priceId,
    //               quantity: quantity
    //             }],
    //             clientReferenceId: this.user.id,
    //             customerEmail: this.user.emailId,
    //             successUrl: 'https://live.onstream.com.ar/plans',
    //             cancelUrl: 'https://live.onstream.com.ar/plans',
    //           });
    //           if (error) {
    //             alert(error);
    //             //console.log(error);
    //           }
    //         } else if (totalAmount_mp == '1020') {
    //           var priceId = 'price_1LQvS4BQezDfn9tgl4ZrRpyE'; //Plan USD basico anual
    //           //Crea session con el plan correspondiente
    //           const stripe = await stripePromise;
    //           const { error } = await stripe.redirectToCheckout({
    //             mode: 'subscription',
    //             lineItems: [{
    //               price: priceId,
    //               quantity: quantity
    //             }],
    //             clientReferenceId: this.user.id,
    //             customerEmail: this.user.emailId,
    //             successUrl: 'https://live.onstream.com.ar/plans',
    //             cancelUrl: 'https://live.onstream.com.ar/plans',
    //           });
    //           if (error) {
    //             alert(error);
    //             //console.log(error);
    //           }
    //         }
    //       }
    //     }
    //   });
    // })
    // .catch(error => console.log('error', error));

    e.preventDefault();

  } //termina checkout5

}