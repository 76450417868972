import { Injectable } from '@angular/core';

@Injectable()
export class AuthServiceConfig {
  issuer: string = 'https://valida.tv/realms/onstream';
  redirectUri: string = location.origin + '/login_redirect';
  clientId: string = 'angular-app';
  scope: string = 'openid profile email';
  responseType: string = 'code';
  showDebugInformation: boolean = true;
  tokenEndpoint: string = 'https://valida.tv/realms/onstream/protocol/openid-connect/token';
}