import { ApiService } from '@base/services/base.api.service';
import { Injectable } from '@angular/core';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable } from 'rxjs';
import { API_REFERENCE, METHOD_REFERENCE, API_PATH } from '@base/constants/base.constants';

@Injectable()
export class EditProfileService {
    public apiService: ApiService;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
    }

    public updateProfile(updateProfile): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['update'],
            updateProfile, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public uploadUserProfileImage(formData, userID: string): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['uploadProfilePicture'] + userID,
            formData, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public updateUserProfileImage(formData): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['updateProfilePicture'],
            formData, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public removeProfile(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['removeProfile'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }
}
