import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../component/search.component';
import { SearchService } from '../services/search.service';
import { LoaderModule } from 'src/app/components/loader/loader.module';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    LoaderModule
  ], exports: [SearchComponent],
  providers: [SearchService]
})
export class SearchModule { }
