import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BaseModule } from '@base/module/base.module';
import { LoaderComponent } from "./loader.component";

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [ LoaderComponent ],
    imports: [
        CommonModule,
        RouterModule,
        BaseModule,
        LottieModule.forRoot({ player: playerFactory })
    ],
    providers: [],
    exports: [LoaderComponent]
})

export class LoaderModule {};