import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { ProfileService } from '@profile/service/profile.service';
import { VideoPageService } from '@video-page/asset-page.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { GetWatchlistData, AllData, ProfileData } from '@profile/model/profile.model';
import { PageData } from '@home/model/pagecategory.model';

@Component({
  selector: 'app-watchlist',
  templateUrl: '../view/watchlist.component.html',
  styleUrls: ['../css/watchlist.component.css']
})
export class WatchlistComponent extends BaseComponent implements OnInit {

  public profileService: ProfileService;
  public videopageService: VideoPageService;
  watchlistData: GetWatchlistData;
  public profile: ProfileData;
  loading: boolean;
  removeToWatchListData: AllData;

  constructor(private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.profileService = ServiceLocator.injector.get(ProfileService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
  }

  async ngOnInit() {
    await this.waitForToken();

    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    window.scroll(0, 0);
    this.getWatchlist();
  }


  public getWatchlist(): void {
    this.loading = true;
    const requestWatchlistData = {
      "deviceTypeId": "1"
    };
    let fiterWatchlistString = "?filter=" + encodeURI(JSON.stringify(requestWatchlistData));
    this.profileService.getWatchlist(fiterWatchlistString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          res.data = res.data.slice(0, 10);
          res.data.forEach(asset => {
            // Set "streamsmas-content" in img paths
            asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
            asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
            asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
            asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

            // Set default duration if not populated
            if (asset.bookmarkDuration && !asset.duration) {
              asset.duration = 7200;
              asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
            }
          });
          this.watchlistData = res;
          this.loading = false;
        } else if (res !== undefined && res.success === false) {
          this.loading = false;
        }
      },
      err => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  public gotoVideoPage(pagedata: PageData): void {
    this.router.navigate(['/video', pagedata.title.split(/\s/).join('').toLowerCase(), pagedata.path]);
  }

  public removeToWatchList(assetItem: AllData): void {
    let index: any;
    const pageRequest = {
      assetId: assetItem.path,
    };
    var self = this;
    this.videopageService.removeToWatchList(pageRequest).subscribe(
      res => {
        if (res !== undefined) {
          this.successResponse(res.message);
          index = self.watchlistData.data.indexOf(assetItem);
          self.watchlistData.data.splice(index, 1);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  public setremoveToWatchListData(setdata: AllData): void {
    this.removeToWatchListData = setdata;
  }
}
