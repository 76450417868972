import { Injectable } from '@angular/core';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';


@Injectable()
export class ToastService {

    constructor(public toastyService: ToastyService, public toastyConfig: ToastyConfig) {
        this.toastyConfig.theme = 'default';
    }

    addToast(toastCase: any, title: any, message: any) {
        var toastOptions: ToastOptions = {
            title: title,
            msg: message,
            showClose: true,
            timeout: 5000,
            onAdd: (toast: ToastData) => {
            },
            onRemove: function (toast: ToastData) {
            }
        };

        switch (toastCase) {
            case 'default': this.toastyService.default(toastOptions); break;
            case 'info': this.toastyService.info(toastOptions); break;
            case 'success': this.toastyService.success(toastOptions); break;
            case 'wait': this.toastyService.wait(toastOptions); break;
            case 'error': this.toastyService.error(toastOptions); break;
            case 'warning': this.toastyService.warning(toastOptions); break;
        }
    }
}