import { BaseComponent } from '@base/component/base.component';
import { OnInit, Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { checkEmailExists, forgotPassword } from 'src/app/api/auth';

@Component({
    selector: 'app-authentication',
    templateUrl: '../view/forgotpassword.component.html',
    styleUrls: ['../css/authentication.component.css']
})

export class ForgotPasswordComponent extends BaseComponent implements OnInit {
    public isLoading: boolean;

    forgotPasswordform: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    ngOnInit() {
        if (this.isLoggedIn)
            this.router.navigate(["home"]);
        
        this.forgotPasswordform.reset();
    }

    async onSubmitForm() {
        this.isLoading = true;
        const body = { emailId: this.forgotPasswordform.value.email as string };

        try {
            await checkEmailExists(body);
            const response = await forgotPassword(body);

            localStorage.removeItem('videoUrl');
            localStorage.setItem('forgotPasswordEmail', body.emailId);
            this.successResponse(response.message);
            this.router.navigate(['set-new-password']);
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    }
}