import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ProfileService } from '@profile/service/profile.service';
import { PlansComponent } from '@plans/plans.component';
import { VideoPageService } from '@video-page/asset-page.service';

@NgModule({
  declarations: [PlansComponent],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [ProfileService, VideoPageService],
  exports: [PlansComponent]
})
export class PlansModule { }
