import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from '@base/component/base.component';
import { BrowserModule } from '@angular/platform-browser';
import { ApiService } from '@base/services/base.api.service';
import { ToastyModule } from 'ng2-toasty';
import { ToastService } from '@base/services/toast.service';

import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      maxOpened: 1
    }),
  ],
  exports: [BrowserModule, ReactiveFormsModule, FormsModule, ToastyModule],
  providers: [BaseComponent, ApiService, ToastService]
})

export class BaseModule { }
