import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveConcertComponent } from '@live-concert/component/live-concert.component';

@NgModule({
  declarations: [LiveConcertComponent],
  imports: [
    CommonModule
  ],
  exports: [LiveConcertComponent]
})
export class LiveConcertModule { }
