import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcToLocalDateProfile'
})
export class UtcToLocalDateProfilePipe implements PipeTransform {
  transform(time: string): string {
    if (time !== '') {
        return moment.utc(time).local().format('hh:mm a');
    } else {
        return '';
    }

}
}
