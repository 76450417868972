import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { HeaderService } from 'src/app/components/header/header.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { SearchService } from '../services/search.service';
import { SearchData, DataList } from '../model/search.model';
import { Params } from '@angular/router';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-search',
  templateUrl: '../view/search.component.html',
  styleUrls: ['../css/search.component.css']
})
export class SearchComponent extends BaseComponent implements OnInit {
  route: ActivatedRoute

  constructor(_activatedRoute: ActivatedRoute,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.searchService = ServiceLocator.injector.get(SearchService);
    this.route = _activatedRoute;
  }

  headerService: HeaderService;
  searchService: SearchService;
  searchData: SearchData;
  public searchSubscription: any;
  searchText: string;
  public is_loading: boolean;
  skip: number;
  viewmore: boolean;

  async ngOnInit() {
    await this.waitForToken();

    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    this.route.params.subscribe((params: Params) => {
      var query = params['query'];
      this.searchData = null;
      window.scroll(0, 0);
      this.searchData = null;

      this.skip = 0;
      this.getSearchResult(query, 0, false);
    });
  }


  public getSearchResult(searchpath: string, skip: number, setdata: boolean): void {
    this.searchText = searchpath;
    if (setdata) {
      this.is_loading = true;
    }

    this.skip = this.skip + skip;

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const SearchObject = { "keyword": searchpath }

    let fiterSearchString = "?filter=" + encodeURI(JSON.stringify(SearchObject));

    this.searchSubscription = this.searchService.getSearchResult(fiterSearchString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          if (res.data.data != null) {

            if (res.data.data.length === 50) {
              this.viewmore = true;
            } else {
              this.viewmore = false;
            }

            res.data.data.forEach(asset => {
              // Set "streamsmas-content" in img paths
              asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
              asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
              asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
              asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

              // Set default duration if not populated
              if (asset.bookmarkDuration && !asset.duration) {
                asset.duration = 7200;
                asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
              }
            });

            if (setdata) {
              res.data.data.forEach(record => {
                this.searchData.data.push(record);
              });
              this.is_loading = false;
            } else {
              this.searchData = res.data.data;
            }
            this.is_loading = false;
          } else {
            this.searchData = new SearchData();
            this.searchData.data = [];
            this.is_loading = false;
          }
        } else if ((res !== undefined && res.status === false)) {
          if (res['msg'] != '') {
            this.searchData = new SearchData();
            this.searchData.data = [];
            this.is_loading = false;
          } else {
            this.is_loading = false;
            this.searchData = null;
          }
        }
      },
      err => {
        this.is_loading = false;
        console.log(err);
      }
    );
  }

  public gotoVideoPage(pagedata: DataList): void {
    if (pagedata.type === 'asset') {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
    } else if (pagedata.type === 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    }
  }

}
