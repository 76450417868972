import { BaseComponent } from '@base/component/base.component';
import { OnInit, Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SetNewPasswordProps } from 'src/app/api/schemas';
import { forgotPassword, setNewPassword } from 'src/app/api/auth';

@Component({
    selector: 'app-authentication',
    templateUrl: '../view/setnewpassword.component.html',
    styleUrls: ['../css/authentication.component.css']
})
export class SetNewPasswordComponent extends BaseComponent implements OnInit {

    isLoading: boolean;
    email: string = null;

    public updatePasswordform = new FormGroup({
        otp: new FormControl('', [Validators.required, Validators.maxLength(6)]),
        new_password: new FormControl('', [Validators.required, Validators.pattern(
            "(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}"
        )]),
        confirm_new_password: new FormControl('', [Validators.required])
    }, this.passwordMatchValidator);

    public passwordMatchValidator(g: FormGroup) {
        return g.get('new_password').value === g.get('confirm_new_password').value ? null : { 'mismatch': true };
    }

    ngOnInit() {
        if (this.isLoggedIn)
            this.router.navigate(["home"]);
       
        this.email = localStorage.getItem("forgotPasswordEmail")
        if (!this.email)
            this.router.navigate(["forgot-password"]);
        
        this.updatePasswordform.reset();
    }

    async onSubmitForm() {
        this.isLoading = true;
        const body: SetNewPasswordProps = {
            emailId: this.email,
            otp: this.updatePasswordform.value.otp,
            password: this.updatePasswordform.value.new_password
        };

        try {
            const response = await setNewPassword(body);

            this.successResponse(response.message);
            localStorage.removeItem("forgotPasswordEmail");
            this.router.navigate(["auth"]);
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    }

    async resetUserPassword() {
            this.isLoading = true;
            const body = { emailId: this.email };

            try {
                const response = await forgotPassword(body);

                this.successResponse(response.message);
                this.router.navigate(['set-new-password']);
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
    }
}
