export class DataList {
    path: any;
    title: string;
    description: string;
    isActive: any;
    adlmId: any;
    deviceName: string;
    vodOrLivePosterImageFileName: string;
    vodOrLivePosterImageFilePath: string;
    vodClosedCaptionFileName: string;
    vodClosedCaptionFilePath: string;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    langaugeId: any;
    langaugeName: string;
    langaugeCode: string;
    dtypeId: string;
    langId: string;
    exclusionText: string;
    sameForAllZone: any;
    sameForAllProductType: any;
    displayIndexing: any;
    label: string;
    airStartDate: string;
    airStartTimezone: string;
    airEndDate: string;
    airEndTimezone: string;
    offlineDownload: any;
    mapTrailerVideo: any;
    uploadVerticalImage: any;
    uploadHorizontalImage: any;
    lyrics: string;
    duration: string;
    seoTitle: string;
    seoKeyword: string;
    seoDescription: string;
    contentType: string;
    ratingCount: any;
    totalRating: any;
    userTotalRating: any;
    likeCount: any;
    isUserLikes: any;
    watchedCount: any;
    isUserWatched: any;
    type: string;
    isPremium: number;
}

export class SearchData {
    status: boolean;
    data: DataList[];
}