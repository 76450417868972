import { FooterService } from '@footer/services/footer.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseComponent } from '@base/component/base.component';

@Injectable()
export class InterceptorService extends BaseComponent implements HttpInterceptor {

    constructor(private cookieService: CookieService,
        private footerService: FooterService) {
        super();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => event),
            catchError(err => {
                if (err.status === 0 || err.status === 401) {
                    localStorage.removeItem('user_token');
                    if (this.user) {
                        this.footerService.reloadCookies.emit(true);
                        this.cookieService.delete('readDisclaimer');
                        this.router.navigate(['']);
                    }
                }
                return throwError(err);
            }));
    }
}