import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPageComponent } from '@video-page/asset-page.component';
import { VideoPageService } from '@video-page/asset-page.service';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonModule } from '@ngx-share/button';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LoaderModule } from 'src/app/components/loader/loader.module';

@NgModule({
  declarations: [VideoPageComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ShareButtonModule,
    FormsModule,
    AngularFireDatabaseModule,
    LoaderModule
  ],
  exports: [VideoPageComponent],
  providers: [VideoPageService]
})
export class VideoPageModule { }
