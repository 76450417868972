import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@base/module/base.module';
import { RouterModule } from '@angular/router';

import { AuthenticationComponent } from '@authentication/component/authentication.component';
import { SetNewPasswordComponent } from '@authentication/component/setnepassword.component';
import { ForgotPasswordComponent } from '@authentication/component/forgotpassword.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from 'src/app/components/loader/loader.module';

@NgModule({
  declarations: [
    AuthenticationComponent,
    ForgotPasswordComponent,
    SetNewPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BaseModule,
    BrowserAnimationsModule,
    LoaderModule,
  ],
  exports: [
    AuthenticationComponent,
    ForgotPasswordComponent,
    SetNewPasswordComponent
  ]
})
export class AuthenticationModule { }
