import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { checkEmailExists, logIn } from 'src/app/api/auth';

@Component({
  selector: 'app-authentication',
  templateUrl: '../view/authentication.component.html',
  styleUrls: ['../css/authentication.component.css']
})

export class AuthenticationComponent extends BaseComponent implements OnInit {
  isLoading: boolean;

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  ngOnInit() {
    if (this.isLoggedIn)
      this.router.navigate(["home"]);
  }

  async onSubmitForm() {
    this.isLoading = true;

    const checkEmailBody = { emailId: this.loginForm.value.email as string };
    const loginBody = {
      emailId: this.loginForm.value.email as string,
      password: this.loginForm.value.password as string,
      loginSource: '3'
    };

    try {
      await checkEmailExists(checkEmailBody);
      const response = await logIn(loginBody);
      
      BaseComponent.setUserToken(response.data[0].token);
      BaseComponent.setUser(response.data[0]);
      BaseComponent.fillAuthData();
      this.loginForm.reset();
      this.successResponse(response.message);
      this.router.navigate(["home"]);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }
}
