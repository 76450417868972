import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WatchlistComponent } from '@watchlist/component/watchlist.component';
import { ProfileService } from '@profile/service/profile.service';
import { LoaderModule } from 'src/app/components/loader/loader.module';


@NgModule({
  declarations: [WatchlistComponent],
  imports: [
    CommonModule,
    LoaderModule
  ],
  providers: [ProfileService],
  exports: [WatchlistComponent]
})
export class WatchlistModule { }
