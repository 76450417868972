import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumComponent } from '@premium/component/premium.component';

@NgModule({
  declarations: [PremiumComponent],
  imports: [
    CommonModule
  ]
})
export class PremiumModule { }
