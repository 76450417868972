import { NgModule, Injector, LOCALE_ID } from '@angular/core';
import { AppComponent } from '@app/component/app.component';
import { AppRoutingModule } from '@app/module/app.routing.module';
import { BaseModule } from '@base/module/base.module';
import { FooterModule } from '@footer/module/footer.module';
import { ProfileModule } from '@profile/module/profile.module';
import { HeaderModule } from 'src/app/components/header/header.module';
import { LiveConcertModule } from '@live-concert/module/live-concert.module';
import { VideoPageModule } from '@video-page/asset-page.module';
import { VideoPlayerPageModule } from 'src/app/video-player-page/video-player-page.module';
import { ServiceLocator } from '@base/constants/service-locator';
import { EditProfileModule } from '@profile/edit-profile/module/edit-profile.module';
import { HomeModule } from '@home/module/home.module';
import { PremiumModule } from '@premium/module/premium.module';
import { AuthenticationModule } from '@authentication/module/authentication.module';
import { AssetGroupDetailsModule } from '@asset-detail/module/asset-group-details.module';
import { GenresListingModule } from '@genres-listing/module/genres-listing.component';
import { SearchModule } from 'src/app/search/module/search.module';
import { FootergenreModule } from '@footer/footergenre/module/footergenre.module';
import { FooterLinkContentModule } from '../../footerLinkContent/module/footerLinkContent.module';
import { InterceptorService } from '@base/services/interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WatchlistModule } from '@watchlist/module/watchlist.module';
import { FavoritesModule } from '@favorite/module/favorites.module';
import { PlansModule } from '@plans/plans.module';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../../environments/environment';
import {FormsModule } from '@angular/forms';
import { ShortNumberPipe } from '../../pipes/short-number.pipe';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthServiceConfig } from '../../auth/service/auth.service.config';
import { AuthModule } from 'src/app/auth/module/auth.module';
registerLocaleData(localeES);

@NgModule({
  declarations: [AppComponent, ShortNumberPipe],
  imports: [
    AppRoutingModule,
    BaseModule,
    HeaderModule,
    HomeModule,
    FooterModule,
    ProfileModule,
    LiveConcertModule,
    VideoPageModule,
    VideoPlayerPageModule,
    EditProfileModule,
    PremiumModule,
    AuthenticationModule,
    AssetGroupDetailsModule,
    GenresListingModule,
    SearchModule,
    FootergenreModule,
    FooterLinkContentModule,
    WatchlistModule,
    FavoritesModule,
    PlansModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AuthModule,
    OAuthModule.forRoot()
  ],
  providers: [
    AuthServiceConfig,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'es'},
    ShortNumberPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
