export const environment = {
  production: false,
  imagePrefix: "assets/images/",
  //apiUrl: 'https://gvog40cv1b.execute-api.us-east-1.amazonaws.com/stage/', // nation traffic stage
  //apiUrl : 'https://oneplayapi.dcafecms.com/',
  apiUrl: 'https://streamsmasapi.dcafecms.com/',
  // apiUrl: 'https://streamsmasapi.dcafecms.com/',
  apiUrlDollywood: "https://dollywoodplay.admin.demo.dctinc.net/api/v1/",
  apiUrlCarousal: "https://hungamakids.admin.stage.dctinc.net/api/v1/",
  // googleAuthKey: '247746380086-fbrglglfl6lvcn575u82248khpbef1j2.apps.googleusercontent.com', //prod
  // googleAuthKey:
  //   "695989545489-ehsia82b9l31723f82dfilfm9rtfbo41.apps.googleusercontent.com", // vuuzle dev
  googleAuthKey: "710599276507-hqtgrj0s5f7veh2492bp1l2966uhen3j.apps.googleusercontent.com",  // nation traffic dev
  // facebookAuthId: "2473374139418803", //prod
  // facebookAuthId: "2239905339471156", // vuuzle
  facebookAuthId: "764620051103367", // nation traffic dev
  voucherkey: 'ST34MS&s3h1jRw#Q',
  stripeKey: "pk_test_9EjrHRgtqlBAKLuFA6oLRMU6",
  nationTraffic: "OnePlay",
  theoPlayer: 'sZP7IYe6T6fL0QarIl0_06zr3DhiFSarClh-TS0c3Oz_0ofzTSek0D0kCL06FOPlUY3zWokgbgjNIOf9flbiIuxgCKBtFSge3Qh-3uar0Zz_IlCrFSCLTu5r3SCtClBc3mfVfK4_bQgZCYxNWoryIQXzImf90Sbz3SeZ0uRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3LBL0Lbi3uBi0lCtFOPeWok1dDrLYtA1Ioh6TgV6WQgoImrKdDc-dYxrFK3qWmfVfKcNUKhpdogpIojKUQ1gbQgzCo1lUY5pCoj-f6i6WQgoImrpCD3NWor1WwxobQckbZrlWoz6FOPVdYIgFKjpIYAVCYepWK4zf6i6WQgoImrqWG3zbK41WmrlWozpCYf6FOPVdYIgFGAgWKXZWociWQXrFK3qWmfVfKcNUKhpbtxZIDX-bok1bZrlWoz6FOPVdYIgFGNqWKXibK4-dD4ZFGxof6i6WQjlCDcEWt3zf6i6WorgbQc1smrLUQXGImreCtxNWK0pWK4zf6i6UQ1gWtAVCYggb6rlWoz6Ymi6IQj-CDgpbkjLWt4ZCoh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfG3edt06TgV6dwx-Wuh6Ymi6bo4pIXjNWYAZIY3LdDjpflNzbG4gFOPKIDXzUYPgbZf9Dkkj',
  firebaseConfig: {
    apiKey: "AIzaSyA4WiOI81ddAcg3HGi19jG4nExctZPsi0k",
    authDomain: "on-stream-fe3ad.firebaseapp.com",
    databaseURL: "https://on-stream-fe3ad-default-rtdb.firebaseio.com",
    projectId: "on-stream-fe3ad",
    storageBucket: "on-stream-fe3ad.appspot.com",
    messagingSenderId: "40944203445",
    appId: "1:40944203445:web:c6b14e738536cddb2b70fc",
    measurementId: "G-442PYZJSN6"
  }
};
