import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from '@base/component/base.component';
import { getGuestToken } from 'src/app/api/auth';
import { getProfile } from 'src/app/api/users';

@Component({
  selector: 'app-root',
  templateUrl: '../view/app.component.html',
  styleUrls: []
})

export class AppComponent extends BaseComponent {
  isHeaderAllowed: boolean;
  isFooterAllowed: boolean

  constructor(public router: Router) {
    super();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isHeaderAllowed = this.isComponentAllowed("header");
        this.isFooterAllowed = this.isComponentAllowed("footer");
      }
    });
  }

  async ngOnInit() {
    await this.fillAuthenticationData();
  }

  async fillAuthenticationData() {
    const { isLoggedIn, token } = BaseComponent.fillAuthData();
    if (!token) 
      await getGuestToken();
    if (isLoggedIn)
      await getProfile(this.userId.toString());
  }

  isComponentAllowed(componentName: string) {
    const notAllowedPages = {
      "header": ["auth-oneplay", "forgot-password", "set-new-password", "video-page", "trailer-page"],
      "footer": ["auth-oneplay", "forgot-password", "set-new-password", "video-page", "trailer-page"]
    }

    const currentPageName = window.location.pathname.split("/")[1];
    return !notAllowedPages[componentName].includes(currentPageName || "/");
  }
}
