import { ServiceLocator } from "@base/constants/service-locator";
import { Injectable, Input, EventEmitter } from "@angular/core";
import { ApiService } from "@base/services/base.api.service";
import { Observable } from "rxjs";
import {
  METHOD_REFERENCE,
  API_REFERENCE,
  API_PATH
} from "@base/constants/base.constants";

@Injectable()
export class HeaderService {
  public apiService: ApiService;

  @Input()
  public reloadHeader: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setSearchPath: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setProfileImg: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setActivePath: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.apiService = ServiceLocator.injector.get(ApiService);
  }

  public userLogout(): Observable<any> {
    const options = this.apiService.getRequestOptions(
      METHOD_REFERENCE["POST"],
      API_REFERENCE["user"]["logout"],
      {},
      null,
      null,
      null,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public getNavigation(navigationObject): Observable<any> {
    const filter =  encodeURI(JSON.stringify(navigationObject));
    const options = this.apiService.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["homepage"]["navigation"] + "?filter=" + filter,
      {},
      null,
      null,
      null,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public getToken(): Observable<any> {
    const options = this.apiService.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["user"]["usertoken"],
      {},
      null,
      null,
      null,
      API_PATH
    );
    return this.apiService.apiCallWihtourtEncryption(options);
  }
}
