import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from '@profile/component/profile.component';
import { LiveConcertComponent } from '@live-concert/component/live-concert.component';
import { VideoPageComponent } from '@video-page/asset-page.component';
import { EditProfileComponent } from '@profile/edit-profile/component/edit-profile.component';
import { HomeComponent } from '@home/component/home.component';
import { PremiumComponent } from '@premium/component/premium.component';
import { AuthenticationComponent } from '@authentication/component/authentication.component';
import { SetNewPasswordComponent } from '@authentication/component/setnepassword.component';
import { ForgotPasswordComponent } from '@authentication/component/forgotpassword.component';
import { GenresListingComponent } from '@genres-listing/component/genres-listing.component';
import { SearchComponent } from 'src/app/search/component/search.component';
import { AssetGroupDetailsComponent } from '@asset-detail/component/asset-group-details.component';
import { FootergenreComponent } from '@footer/footergenre/component/footergenre.component';
import { FooterLinkContentComponent } from '../../footerLinkContent/component/footerLinkContent.component';
import { WatchlistComponent } from '@watchlist/component/watchlist.component';
import { FavoritesComponent } from '@favorite/component/favorites.component';
import { VideoPagePlayerComponent } from 'src/app/video-player-page/video-page-player.component';
import { PlansComponent } from '@plans/plans.component';
import { AuthComponent } from '@auth/component/auth.component';


const routes: Routes = [
  { path: 'login_redirect', component: AuthComponent },
  { path: '', redirectTo: "/home", pathMatch: "full" },

  { path: 'menu/:path/:type', component: HomeComponent },
  {
    path: 'profile',
    children: [
      { path: '', component: ProfileComponent },
      { path: 'edit-profile', component: EditProfileComponent }
    ]
  },
  { path: 'auth-oneplay', component: AuthenticationComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'set-new-password', component: SetNewPasswordComponent },
  { path: 'live-concert', component: LiveConcertComponent },
  { path: 'video/:title/:path', component: VideoPageComponent },
  { path: 'video-page/:title/:id', component: VideoPagePlayerComponent },
  { path: 'trailer-page/:title/:id', component: VideoPagePlayerComponent },
  { path: 'premium', component: PremiumComponent },
  { path: 'geners/:navigationpath/:path/:genrePath', component: GenresListingComponent },
  { path: 'geners/:navigationpath/:path', component: GenresListingComponent },
  { path: 'search/:query', component: SearchComponent },
  { path: 'season/:serie', component: AssetGroupDetailsComponent },
  { path: 'season/:serie/:season/:chapter', component: AssetGroupDetailsComponent },
  { path: 'category/:title/:path', component: FootergenreComponent },
  { path: 'footer/:title/:path', component: FooterLinkContentComponent },
  // { path: 'terms-of-use/:path', component: FooterLinkContentComponent },
  // { path: 'privacy-policy/:path', component: FooterLinkContentComponent },
  { path: 'watchlist', component: WatchlistComponent },
  { path: 'favorite', component: FavoritesComponent },
  { path: 'plans', component: PlansComponent },
  { path: ':name', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
