import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from '@base/component/base.component';
import { BaseRequestProps } from '../../api/api';

@Injectable()
export class ApiService {

  constructor(public http: HttpClient) { }

  newApiCall({ method, url, body, headers }: BaseRequestProps): Promise<any> {
    const httpRequest = new HttpRequest(method, url, body, { headers })
    return this.http.request(httpRequest).toPromise();
  }

  apiCall(httpRequest: any): Observable<any> {
    return this.http.request(httpRequest).pipe(
      map((response: HttpEvent<any>) => this.getAPIResponse(response)));
  }

  getRequestOptions(
    method: any,
    url: any,
    data: any,
    params: URLSearchParams = new URLSearchParams(),
    headers: Headers = new Headers(),
    isFormData: boolean = false,
    apiUrl: string = ''
  ) {
    const headerSettings: { [name: string]: string | string[] } = {};

    if (headers != null && !isFormData) {
      headerSettings['Content-Type'] = 'application/json';
    }

    if (BaseComponent.token) {
      headerSettings['Authorization'] = BaseComponent.token;
    }
    const header = new HttpHeaders(headerSettings);
    const httpInit = {
      headers: header,
      search: params
    };

    return new HttpRequest(method, apiUrl + url, data, httpInit);
  }

  getAPIResponse(httpEvent: HttpEvent<any>) {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        break;
      case HttpEventType.Response:
        var body = httpEvent.body;
        var base64Url = body.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      // return httpEvent.body;
    }
  }

  apiCallWihtourtEncryption(httpRequest: any): Observable<any> {
    return this.http.request(httpRequest).pipe(
      map((response: HttpEvent<any>) => this.getAPIResponseWihtourtEncryption(response)));
  }
  getAPIResponseWihtourtEncryption(httpEvent: HttpEvent<any>) {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        break;
      case HttpEventType.Response:
        return httpEvent.body;
    }
  }
  apiCallThirdParty(httpRequest: any) {
    return this.http.get(httpRequest);
  }
}
