import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterLinkContentComponent } from '../component/footerLinkContent.component';
import { FooterLinkContentService } from '../services/footerLinkContent.service';

@NgModule({
  declarations: [FooterLinkContentComponent],
  imports: [
    CommonModule
  ],
  exports: [FooterLinkContentComponent],
  providers: [FooterLinkContentService]
})
export class FooterLinkContentModule { }
