import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@footer/component/footer.component';
import { FooterService } from '@footer/services/footer.service';
import { BaseModule } from '@base/module/base.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule, BaseModule
  ],
  exports: [FooterComponent],
  providers: [FooterService]
})
export class FooterModule { }
