import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';
import { CurousalResponse } from '@home/model/carousal';

@Injectable()
export class HomeService {

    public apiService: ApiService;
    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
    }

    public getPageCategories(pageCategoryObject): Observable<any> {
        //const filter =  encodeURI(JSON.stringify(pageCategoryObject));
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['pagecategories']['pagecategorie'] + pageCategoryObject,
            {},
            null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getMainCarousal(carouselObject): Observable<CurousalResponse> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['homepage']['carousel'] + carouselObject,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getDefualtPageList(pageCategoryListingObject): Observable<any> {
        //const filter =  encodeURI(JSON.stringify(pageCategoryListingObject));
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['pagecategories']['defaultlist']+ pageCategoryListingObject,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }
}