import { Pipe, PipeTransform } from '@angular/core';
import { PageData } from '@home/model/pagecategory.model';
import { carouselData } from '@home/model/carousal';

@Pipe({ name: 'gotoVideoPage' })
export class GoToVideoPage implements PipeTransform {
    transform(pagedata: PageData, carousal: carouselData): string {
        if (pagedata !== null) {
            if (pagedata.type === 'asset' && pagedata.path !== null) {
                return '/video/' + pagedata.title.split(/\s/).join('').toLowerCase() + '/' + pagedata.path;
            } else if (pagedata.type === 'asset_group') {
                return '/season/' + pagedata.path;
            }
        } else {
            if (pagedata.type === 'asset') {
                return '/video/' + pagedata.title.split(/\s/).join('').toLowerCase() + '/' + pagedata.path;
            } else if (pagedata.type === 'asset_group') {
                return '/season/' + pagedata.path;
            }
        }
    }

}