import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})

export class LoaderComponent extends BaseComponent implements OnInit {

  @Input() model: boolean;
  loaderOptions: AnimationOptions = {
    path: `${this.imagePrefix}loader/loader.json`,
  };
  loaderStyles: Partial<CSSStyleDeclaration> = {
    maxWidth: "200px",
  };

  ngOnInit() { }
}
