import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable } from 'rxjs';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';

@Injectable()
export class VideoPageService {
    public apiService: ApiService;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
    }

    public getAssetDetails(path,urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['asset']['assetdetails'] + path + urlParam,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public addTofavourite(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['addfavourite'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public addassetgroupTofavourite(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['assetgroupfavourite'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public removeAssetgroupTofavourite(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['removeAssetgroupfavourite'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public removeTofavourite(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['removefavourite'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public addToWatchlist(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['addwatchlist'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public removeToWatchList(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['removeWatchList'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getRelatedVideo(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['asset']['relatedvideo']+urlParam,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getSuggestedvideos(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['asset']['suggestedvideos'] + urlParam,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getCastCrews(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['asset']['castcrews'] + urlParam,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public addToBookmark(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['asset']['bookmark'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getSubScriptionList(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['payment']['subscription']+ urlParam,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }

    public placeOrder(urlParam): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['payment']['placeorder'],
            urlParam, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }
    public getPreplayDetails(urlParam):Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['asset']['preplay']+urlParam,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
    }
    public getPreplayCertificateDetails(urlParam){
        // console.log(urlParam);
        // const options = this.apiService.getRequestOptionsWithoutHeader(
        //     METHOD_REFERENCE['GET'], urlParam,null);
        // console.log(options);
        
        return this.apiService.apiCallThirdParty(urlParam);
    }

    public CheckPlanValidity(filterData): Observable<any> {
        const options = this.apiService.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['user']['userplans']+filterData,
            {}, null, null, null, API_PATH);
        return this.apiService.apiCall(options);
      }
}