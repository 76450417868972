import { FooterService } from '@footer/services/footer.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { HeaderService } from './header.service';
import { NavigationPage } from './header.model';
import { NavigationEnd, RouterEvent, NavigationStart } from '@angular/router';
import { ProfileService } from '@profile/service/profile.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthServiceConfig } from '@auth/service/auth.service.config';
declare var $: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit {
	private destroyed$ = new Subject();
	navigationData: NavigationPage[];
	profile: any = null;
	activePath: string;
	refereshToken: {};
	logo: string;
	isLoading: boolean = false;

	profileService: ProfileService;
	headerService: HeaderService;
	footerService: FooterService;

	constructor(private oAuthService: OAuthService) {
		super();
		this.profileService = ServiceLocator.injector.get(ProfileService);
		this.headerService = ServiceLocator.injector.get(HeaderService);
		this.footerService = ServiceLocator.injector.get(FooterService);
	}

	async ngOnInit() {
		await this.waitForToken();

		// Hide SideMenu and SearchInputMobile when navigate
		this.router.events
			.pipe(
				filter((event: RouterEvent) => event instanceof NavigationStart),
				takeUntil(this.destroyed$),
			)
			.subscribe(() => {
				this.closeSideMenu();
				$("#searchInputContainerMobile").removeClass("show");
			});

		this.getNavigation();

		this.headerService.setActivePath.subscribe(res => {
			if (res !== undefined && res !== '') {
				this.activePath = res;
			}
		});

		if (this.userId) {
			this.getProfile();
		}

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				var text = event.url;

				var currentActivePath = text.replace('/', '');
				this.activePath = currentActivePath.toLowerCase();
			}
		});

		$(document).mouseup(function (e) {
			var searchSuggestion = $(".searchInputMobile");
			var searchMobile = $(".search-box-mobile");
			if (!searchMobile.is(e.target) && searchMobile.has(e.target).length === 0) {
				if (!searchSuggestion.is(e.target) && searchSuggestion.has(e.target).length === 0) {
					$(".search-box-mobile").fadeOut(500);
				}
			}
		});
	}


	public getProfile(): void {
		this.profileService.getUserProfile(this.userId).subscribe(
			res => {
				if (res !== undefined) {
					this.profile = res.data[0];
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	public goToLoginPage(): void {
		//LOGIN LOCAL
		// localStorage.setItem('videoUrl', this.router.url)
		// this.router.navigate(['auth-oneplay']);

		this.oAuthService.configure(new AuthServiceConfig());
		this.oAuthService.loadDiscoveryDocumentAndTryLogin();
		this.oAuthService.initLoginFlow();
	}

	public getNavigation(): void {
		const navigationObject = {
			deviceTypeId: 1,
			langaugeCode: 1
		}
		this.headerService.getNavigation(navigationObject).subscribe(
			res => {
				if (res !== undefined) {
					this.navigationData = res.data.data;
					this.logo = res.data.logo;
					if (this.logo != undefined) {
						this.footerService.reloadFooter.emit(res.data.logo);
					}
					this.footerService.reloadFooter.subscribe(res => {
					});

					for (const navigate of this.navigationData) {
						if (navigate.title.toUpperCase() === 'HOME') {
							var url = this.router.url.split('?')[0];
							if (url == '/' || url == '')
								this.router.navigate(['', navigate.title.toLowerCase()]);
							return;
						}
					}
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	public getPageCategorie(navigation: NavigationPage, reset: boolean): void {
		$('#sidebar').removeClass('active');
		$('.overlay').removeClass('active');
		this.router.navigate(['', navigation.title.split(" ").join("").toLowerCase()]);
		return;
	}

	onKeyDownSearchInput(event) {
		if (event.key === 'Enter') {
			this.searchAssets(event.target.value);
			$('#searchInput').val('');
			$('#searchInputMobile').val('');
			$(".search-box-mobile").hide();
		}
	}

	searchAssets(value?: string) {
		if (!value)
			value = $("#searchInput").val();

		if (!value || value.trim().length < 3) {
			this.errMessageResponse('Por favor, ingresa al menos 3 caracteres');
			return;
		}

		if (!this.router.url.includes('/search')) {
			this.headerService.setSearchPath.emit(value);
		}
		this.router.navigate(['/search', value]);
	}

	public goTo(page: "plans" | "cuponera"): void {

		var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');


		var tenant_hidden = this.tenant;
		var payment_type_hidden = page === "plans" ? 'SUB' : "ALQ";
		var url_path_hidden = 'https://live.onstream.com.ar/';
		var client_id_hidden = this.user.id;
		var title_hidden = 'sidebar';
		var dni_hidden = dni[0];

		var html = `
      <form action="${page === 'plans'
				? 'https://onstream.com.ar/payment-subscription'
				: 'https://onstream.com.ar/payment-tvod'
			}" method="post" id="formHidden${page}" style="display:none;">
        <input type="hidden" id="tenant_hidden" name="tenant_hidden" value="${tenant_hidden}">
        <input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="${payment_type_hidden}">
        <input type="hidden" id="url_path_hidden" name="url_path_hidden" value="${url_path_hidden}">
        <input type="hidden" id="client_id_hidden" name="client_id_hidden" value="${client_id_hidden}">
        <input type="hidden" id="title_hidden" name="title_hidden" value="${title_hidden}">
        <input type="hidden" id="dni_hidden" name="dni_hidden" value="${dni_hidden}">
        <button type="submit">ADQUIRIR</button>
      </form>`;
		$("#hiddenFormContainer").append(html);
		$(`#formHidden${page}`).submit();
		this.closeSideMenu()
		this.isLoading = true;
	}

	public closeModel(): void {
		$('#sidebar').removeClass('active');
		$('.overlay').removeClass('active');
	}

	openSideMenu() {
		$("#sideMenu").addClass("show");
		$("#sideMenuOverlay").addClass("show");
	}
	closeSideMenu() {
		$("#sideMenu").removeClass("show");
		$("#sideMenuOverlay").removeClass("show");
	}

	toggleMobileSearchInput() {
		$("#searchInputContainerMobile").toggleClass("show");
	}

	onChangeSearchInput(e, screen: "mobile" | "desktop") {
		// Keep Mobile and Desktop inputs the same value
		if (screen === "desktop") {
			$("#searchInputMobile").val(e.target.value);
		} else {
			$("#searchInput").val(e.target.value);
		}
	}
}
