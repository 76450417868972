import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, Renderer2, HostListener, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { AssetGroupDetailService } from '@asset-detail/services/asset-group-details.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { AssetGroupDetail, AssetCategotyDetail } from '@asset-detail/model/asset-group-detail.model';
import { Params, ActivatedRoute } from '@angular/router';
import { AssetGroupListing } from '@asset-detail/model/asset-group-listing.model';
import { CastCrews, VideoPageData } from '@video-page/asset-page.model.js';
import { HeaderService } from 'src/app/components/header/header.service';
import { environment } from '@environments/environment';
import * as THEOplayer from '../../../assets/js/TheoPlayer/THEOplayer.js';
import { PageData } from '@home/model/pagecategory.model';
import CryptoJS from 'crypto-js';
import { stringToUrlFriendly } from 'src/utils/strings.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var $: any;
@Component({
	selector: 'app-asset-group-details',
	templateUrl: '../view/asset-group-details.component.html',
	styleUrls: ['../css/asset-group-details.component.css']
})
export class AssetGroupDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
	@ViewChild('voucherOneplay', { static: false }) voucherOneplay: ElementRef;

	route: ActivatedRoute;

	screenIsMobile: boolean = window.innerWidth < 600;
	assetGroupService: AssetGroupDetailService;
	assetGroupData: AssetGroupDetail;
	assetCategoryData: AssetCategotyDetail;
	assetGroupList: AssetGroupListing;
	skip: number = 0;
	seriePath: string;
	videoPageData: VideoPageData;
	playerObject: any;
	videoURL: string;
	posterImage: string;
	videoTitle: string;
	videoDescription: string;
	director: string;
	cast: string;
	castCrewData: CastCrews;
	viewMore: boolean;
	addedToWatchList: any = 0;
	bookmarkDuration: any;
	loading: boolean;
	mainloading = true;
	headerService: HeaderService;
	trailerPlayBackUrl: string;
	isShowVideo: boolean = false;
	watchMovie: boolean = false;
	setBookMark: boolean = false;
	showVideoError: boolean = false;
	isShowImage: boolean = false;
	geoStatus: number = 1;
	isIncreaseCount: boolean = false;
	showButton: boolean;
	playTimeOut: any;
	bitMovieUnsubsribe: any;
	noGenre: boolean;
	isUserLikes: any;
	isAssetSelected: boolean = false;
	videoPath: any;
	seasonPath: any = 0;
	currentIndex = 0;
	currentAssetPath: string;
	seconds = 5;
	videoFinish: boolean;
	replay: any;
	secondInterval: any;
	secondsTimeout: any;
	clickOntrailer = false;
	csaiAdServer: string;
	lastVideo = false;
	genreTitle = '';
	drm: any;
	preplayParameters: any;
	preplayres: any;
	paymentId: string;


	subScriptionData = [];

	constructor(activateRoute: ActivatedRoute,
		private rendrer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
		private http: HttpClient
		) {
		super();
		this.assetGroupService = ServiceLocator.injector.get(AssetGroupDetailService);
		this.headerService = ServiceLocator.injector.get(HeaderService);
		this.route = activateRoute;
	}

	async ngOnInit() {
		await this.waitForToken();

		this.rendrer.removeClass(this.document.body, 'transperent-layout');
		this.rendrer.removeClass(this.document.body, 'hide-header');
		this.rendrer.removeClass(this.document.body, 'hide-footer');

		this.route.queryParams.subscribe(params => {
			this.paymentId = params['payment_id'];
		});
		// if (localStorage.getItem('user_token')) {
			// window.onscroll = this.adjustPlayer;
			this.mainloading = true;
			$(document).ready(function () {
				$(this).scrollTop(0);
			});
			this.videoPageData = null;
			this.route.params.subscribe((params: Params) => {
				this.lastVideo = false;
				this.videoFinish = false;
				this.clickOntrailer = false;

				if (!this.isAssetSelected) {
					this.setSelectedAsset({
						seriePath: params['serie'],
						seasonPath: params['season'],
						currentAssetPath: params['chapter']
					})
				}

				window.scroll(0, 0);
			});
		// } else {
		// 	localStorage.setItem('videoUrl', this.router.url)
		// 	this.warningMessageResponse('Inicia sesión para ver este contenido');
		// }

		if (this.paymentId) {
			const url = 'https://api.oneplay.net/processes/payments/axion/webhook_manual.php'
			const headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			});
			
			const body = new URLSearchParams();
			body.set('payment_id', this.paymentId);

			this.http.post(url, body.toString(), { headers: headers,  responseType: 'text' }).subscribe(
			data => {
				const urlWithoutQueryParams = this.router.url.split('?')[0];
				console.log(data);
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
					this.router.navigate([urlWithoutQueryParams]);
				});
			},
			error => {
				console.log('Error:', error);
			}
			);
		}
	}

	ngAfterViewInit(): void {
		this.listenToPasteEvents();
	}
	listenToPasteEvents(): void {
		const container: HTMLElement = this.voucherOneplay.nativeElement;
		container.addEventListener('paste', this.handlePasteInOtpInputs.bind(this));
	}


	setSelectedAsset({
		seriePath, seasonPath, currentAssetPath
	}: {
		seriePath?: string, seasonPath?: string, currentAssetPath?: string
	}) {
		this.currentIndex = 0;
		this.seriePath = seriePath || this.seriePath;
		this.seasonPath = seasonPath || this.seasonPath;
		this.currentAssetPath = currentAssetPath || this.currentAssetPath;
		this.getAssetGroupDetails(this.seriePath);
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.screenIsMobile = window.innerWidth < 600;
	}

	public getAssetGroupDetails(seriePath: string): void {

		const assetGroupDetailRequest = {
			"path": seriePath
		};
		let genreFiterString = "?filter=" + encodeURI(JSON.stringify(assetGroupDetailRequest));
		this.assetGroupService.getAssetGroupDetails(genreFiterString).subscribe(res => {
			if (res !== undefined && res.success == true) {
				this.assetGroupData = res;
				this.seasonPath = this.seasonPath || this.assetGroupData.data[0].assetCategory[0].path
				this.getAssetGroupListing(0, this.seasonPath, false);
			}
		},
			err => {
				console.log(err);
			});
	}

	public getAssetGroupListing(skip: number = 0, seasonPath: string, setData: boolean): void {
		this.clickOntrailer = false;
		this.currentIndex = 0;
		this.seasonPath = seasonPath;
		const assetGroupListingRequest = {
			"path": seasonPath,
			"type": "asset_category",
			"deviceTypeId": "1",
		};
		if (setData) {
			this.loading = true;
		}
		let assetGroupListingString = `?filter=${encodeURI(JSON.stringify(assetGroupListingRequest))}&start=${this.skip + skip}&limit=24`;
		this.assetGroupService.getAssetListing(assetGroupListingString).subscribe(res => {
			if (res !== undefined && res.success === true) {
				if (res.data.length == 24) {
					this.viewMore = true;
				} else {
					this.viewMore = false;
				}
				let selectedAssetIndex = 0
				res.data.forEach((asset, i) => {
					// Set Real currentIndex

					if (asset.path == this.currentAssetPath) {
						selectedAssetIndex = i;
					}

					// Set "streamsmas-content" in img paths
					asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
					asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
					asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
					asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

					// Set default duration if not populated
					if (asset.bookmarkDuration && !asset.duration) {
						asset.duration = 2700;
						asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
					}
				});
				if (setData) {
					res.data.forEach(record => {
						record.description = record.description.replace(/<[^>]+>/g, "");
						this.assetGroupList.data.push(record);
					});
					this.loading = false;

				} else {
					this.assetGroupList = res;
					res.data.forEach(record => {
						record.description = record.description.replace(/<[^>]+>/g, "");
					});
					this.getVideoDetails(this.assetGroupList.data[selectedAssetIndex].path, this.currentIndex);
					this.loading = false;
				}
			}
		},
			err => {
				console.log(err);
			});
	}

	// public getAssetCategoryDetails(serie: string, startvideo): void {
	//   this.currentIndex = 0;
	//   this.seconds = 5;
	//   this.assetGroupList = undefined;
	//   this.clickOntrailer = false;
	//   // this.playerObject = undefined;
	//   // this.mainloading = true;
	//   // window.scroll(0,0);
	//   const assetCategoryDetailRequest = {
	//     "path": assetPath
	//   };
	//   let assetCategoryFilterString = "?filter=" + encodeURI(JSON.stringify(assetCategoryDetailRequest));
	//   this.assetGroupService.getAssetCategoty(assetCategoryFilterString).subscribe(res => {
	//     if (res !== undefined && res.success === true) {
	//       console.log("category details", {res})
	//       this.assetCategoryData = res.data[0];
	//       this.skip = 0;
	//       this.getAssetGroupListing(0, this.assetCategoryData, false, startvideo);
	//     }
	//   },
	//     err => {
	//       console.log(err);
	//     });
	// }

	public getVideoDetails(videoPath: string, currenIndex): void {
		this.loading = true;
		this.lastVideo = false;
		this.csaiAdServer = '';
		this.clickOntrailer = false;
		this.currentIndex = currenIndex;
		this.videoFinish = false;
		this.seconds = 5;
		this.genreTitle = '';
		this.bookmarkDuration = null;
		this.videoPath = videoPath.toString();
		this.getCastCrew(this.videoPath);
		this.mainloading = true;
		if (this.playerObject != undefined) {
			this.playerObject.destroy();
			this.playerObject = undefined;
		}
		// const width = window.innerWidth;
		// const height = window.innerHeight;
		// const cookie = this.cookieService.get('readDisclaimer');
		// var dnt = 0;
		// if (cookie == 'true') {
		//   dnt = 1;
		// } else {
		//   dnt = 0;
		// }
		const assetDetailRequest = { "deviceTypeId": "1" };
		// const userAgent = window.navigator.userAgent;
		const clearCacheParams = `&randomParam=${Math.floor(Math.random() * 99)}`;
		const assetDetailFiterString = "?filter=" + encodeURI(JSON.stringify(assetDetailRequest));
		// + '&width=' + width + '&height=' + height + '&dnt=' + dnt + '&ua=' + userAgent;
		this.assetGroupService.getAssetDetails(this.videoPath, assetDetailFiterString + clearCacheParams).subscribe(res => {
			if (res !== undefined && res.success === true) {
				this.videoPageData = res.data[0];
				//this.addedToWatchList = this.videoPageData.isUserWatched;

				// Set "streamsmas-content" in img paths
				this.videoPageData.vodOrLivePosterImageFilePath = this.setTenantBucket(this.videoPageData.vodOrLivePosterImageFilePath);
				this.videoPageData.vodClosedCaptionFilePath = this.setTenantBucket(this.videoPageData.vodClosedCaptionFilePath);
				this.videoPageData.verticalFilePath = this.setTenantBucket(this.videoPageData.verticalFilePath);
				this.videoPageData.horizontalFilePath = this.setTenantBucket(this.videoPageData.horizontalFilePath);

				this.videoURL = this.videoPageData.playbackUrl;
				this.posterImage = this.videoPageData.horizontalFilePath;
				this.trailerPlayBackUrl = this.videoPageData.mapTrailerVideo;
				this.isUserLikes = this.videoPageData.isUserLikes;

				// this.csaiAdServer = this.videoPageData.csaiAdServer;

				// if (this.trailerPlayBackUrl == '') {
				//   this.showButton = false;
				// } else if (this.trailerPlayBackUrl == null) {
				//   this.showButton = false;
				// } else if (!this.trailerPlayBackUrl) {
				//   this.showButton = false;
				// } else {
				//   this.showButton = true;
				// }
				this.drm = this.getDRM();
				this.preplayParameters = '';
				const preplayDetailRequest = "?assetId=" + this.videoPath + "&drm=" + this.drm; //+ id 
				this.assetGroupService.getPreplayDetails(preplayDetailRequest).subscribe(resPreplay => {
					if (resPreplay !== undefined && resPreplay.success === true) {
						this.preplayParameters = resPreplay;

						this.bookmarkDuration = this.videoPageData.bookmarkDuration;
						this.getGenreTitle(this.videoPageData);
						if (this.bookmarkDuration == null) {
							this.bookmarkDuration = 0;
						}
						this.geoStatus = this.videoPageData.geoStatus;

						window.scroll(0, 0);
						this.loading = false;
						this.mainloading = false;
					}
				}, err => {
					this.loading = false;
					this.mainloading = false;
					console.log(err);
				})
			}
		},
			err => {
				this.loading = false;
				console.log(err);
			});
	}
	getDRM() {
		if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
			return 'Opera';
		} else if ((navigator.userAgent.indexOf("MSIE") != -1) || (navigator.userAgent.indexOf("Edg") != -1) || (!!document['documentMode'] == true)) {
			return 'pr';
		} else if (navigator.userAgent.indexOf("Chrome") != -1) {
			return 'wv';
		} else if (navigator.userAgent.indexOf("Safari") != -1) {
			return 'fps';
		} else if (navigator.userAgent.indexOf("Firefox") != -1) {
			return 'wv';
		} else {
			return 'unknown';
		}
	}

	showVideo(videoUrl, posterImage, watchMovie): void {

		var self = this;

		const element = document.querySelector('.theoplayer-container') as HTMLElement;
		this.playerObject = new THEOplayer.Player(element, {
			libraryLocation: 'src/assets/js/TheoPlayer/',//'https://cdn.myth.theoplayer.com/6f50fb0b-51a1-43fd-b602-626e6082956b',
			license: environment.theoPlayer,
			ui: {
				language: 'esp',
				languages: {
					'esp': {
						"Play": "Reproducir",
						"Pause": "Pausa",
						"Current Time": "Tiempo reproducido",
						"Duration Time": "DuraciÃ³n total",
						"Remaining Time": "Tiempo restante",
						"Stream Type": "Tipo de secuencia",
						"LIVE": "DIRECTO",
						"Loaded": "Cargado",
						"Progress": "Progreso",
						"Fullscreen": "Pantalla completa",
						"Exit fullscreen": "Salir de pantalla completa",
						"Mute": "Silenciar",
						"Unmute": "No silenciado",
						"Playback Rate": "Velocidad de reproducciÃ³n",
						"Subtitles": "Subtítulos",
						"subtitles off": "Subtítulos desactivados",
						"Captions": "Subtítulos especiales",
						"captions off": "Subtítulos especiales desactivados",
						"Chapters": "Capítulos",
						"Settings": "Ajustes",
						"Speed": "Velocidad",
						"Language": "Idioma",
						"Subtitle": "Subtítulos",
						"Options": "Opciones",
						"Subtitle options": "Opciones de Subtítulos",
						"Font family": "Tipo de Fuente",
						"Font color": "Color de Fuente",
						"Font opacity": "Opacidad de Fuente",
						"Font size": " Tamaño de Fuente",
						"Background color": "Color de Fondo",
						"Background opacity": "Opacidad del Fondo",
						"Window color": "Color de Ventana",
						"Window opacity": "Opacidad de Ventana",
						"Default": "Predeterminado",
						"default": "Predeterminado",
						"White": "Blanco",
						"Yellow": "Amarillo",
						"Green": "Verde",
						"Cyan": "Cian",
						"Blue": "Azul",
						"Magenta": "Magenta",
						"Red": "Rojo",
						"Black": "Negro",
						"None": "Ninguna",
						"Drop Shadow": "Sombra Paralela",
						"Raised": "Elevada",
						"Depressed": "Deprimida",
						"Uniform": "Uniforme",
						// "Default":"Predeterminado",
						"Subtitle Options": "Opciones de Subtítulos",
						"Character edge style": "Estilo de borde de personaje",
						"You aborted the media playback": "Ha interrumpido la reproducciÃ³n del vÃ­deo.",
						"A network error caused the media download to fail part-way.": "Un error de red ha interrumpido la descarga del vÃ­deo.",
						"The media could not be loaded, either because the server or network failed or because the format is not supported.": "No se ha podido cargar el vÃ­deo debido a un fallo de red o del servidor o porque el formato es incompatible.",
						"The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "La reproducciÃ³n de vÃ­deo se ha interrumpido por un problema de corrupciÃ³n de datos o porque el vÃ­deo precisa funciones que su navegador no ofrece.",
						"No compatible source was found for this media.": "No se ha encontrado ninguna fuente compatible con este vÃ­deo."
					}
				}
			},
			// textTracks: [{
			//   default: true,
			// }]
			// license : 'sZP7IYe6T6fc0l0_TSCrImkK3Sg1FSaiCl5-Clbi0OzkIK3g0SxlCLxl0lB6FOPlUY3zWokgbgjNIOf9flbiIuxgCKBtFSge3Qh-3uar0Zz_IlCrFSCLTu5r3SCtClBc3mfVfK4_bQgZCYxNWoryIQXzImf90SCk0Sa_3SCi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lBt0ufz0Le_3uhzFOPeWok1dDrLYtA1Ioh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOPqWK4iWQXrFG3zCDUgFKxlUQgpCZrpIYa6Ymi6IQj-CDgpbkjLWt4ZCoh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOfJfgzVfG3edt06TgV6dwx-Wuh6Ymi6bo4pIXjNWYAZIY3LdDjpflNzbG4gya'
		});

		if (watchMovie == true && this.geoStatus == 1) {
			// this.playerObject.src({src: videoUrl, type: 'application/x-mpegURL'});
			//this.playerObject.poster(posterImage);
			this.playerObject.source = {
				sources: {
					integration: 'verizon-media',
					id: this.preplayParameters.data.preplay.cid,
					preplayParameters: this.preplayParameters.data.preplay,
					assetType: 'asset',
					contentProtected: true
				}
			}
			this.playerObject.autoplay = true;

			const addToBookmarkJavascript = function (current_playback_position) {
				if (current_playback_position != 0) {
					if (self.setBookMark) {
						if (self.isLoggedIn) {
							const pageRequest = {
								assetId: self.videoPageData.path,
								duration: current_playback_position
							};
							self.assetGroupService.addToBookmark(pageRequest).subscribe(
								result => {
									if (result !== undefined && result.success == true) {
										self.bookmarkDuration = result.data.duration;
									}
								}
								, err => {
									console.log(err);
								}
							);
						}
					}
				}
			};

			const addToBookmarkOnTimeUpdateJavascript = function () {
				let currPlaybackTime = self.playerObject.currentTime;
				if (currPlaybackTime != 0) {
					let diffInTime = 0;
					if (parseInt(currPlaybackTime) > parseInt(self.bookmarkDuration)) {
						diffInTime = parseInt(currPlaybackTime) - parseInt(self.bookmarkDuration);
					} else {
						diffInTime = parseInt(self.bookmarkDuration) - parseInt(currPlaybackTime);
					}
				}
			};
			const onTimeChanged = function (data) {
				if (self.setBookMark) {
					// addToBookmarkOnTimeUpdateJavascript();
				}
				if (self.addedToWatchList == 0) {
					if (self.isLoggedIn) {
						self.addToWatchlist();
						self.playerObject.removeEventListener('timeupdate', onTimeChanged);
					}

				}
			};

			var onTimePaused = function (data) {
				let currPlaybackTime = self.playerObject.currentTime;
				addToBookmarkJavascript(currPlaybackTime);
			};

			var onVideoPlay = function (current_playback_position) {
				// console.log('getFacebookPixle call');
				// self.getFacebookPixle();
			};

			var onFinished = function () {
				self.seconds = 5;
				self.videoFinish = true;
				self.replay = false;
				self.setBookMark = false;
				self.watchMovie = false;
				clearInterval(self.secondInterval);
				clearTimeout(self.secondsTimeout);
				if (self.assetGroupList.data.length > 0) {
					if (self.assetGroupList.data.length != self.currentIndex + 1) {
						self.secondInterval = setInterval(() => {
							self.seconds = self.seconds - 1;
							if (self.seconds == 1) {
								clearInterval(self.secondInterval);
							}
						}, 1000);
						self.secondsTimeout = setTimeout(() => {
							if (self.replay == false) {
								self.videoFinish = false;
								self.mainloading = true;
								self.nextVideo();
							}
						}, 5000);
					} else {
						self.lastVideo = true;
					}
				}
			};
			var onError = function () {
				self.seconds = 5;
				self.videoFinish = true;
				self.replay = false;
				if (self.assetGroupList.data.length > 0) {
					if (self.assetGroupList.data.length != self.currentIndex + 1) {
						self.secondInterval = setInterval(() => {
							self.seconds = self.seconds - 1;
							if (self.seconds == 1) {
								clearInterval(self.secondInterval);
							}
						}, 1000);
						self.secondsTimeout = setTimeout(() => {
							if (self.replay == false) {
								self.videoFinish = false;
								self.mainloading = true;
								self.nextVideo();
							}
						}, 5000);
					}
				}
			};
			const onLoadedData = function () {
				self.seekPlayer(0, true, self.setBookMark);
			};
			self.playerObject.addEventListener('timeupdate', onTimeChanged);
			self.playerObject.addEventListener('pause', onTimePaused);
			self.playerObject.addEventListener('ended', onFinished);
			self.playerObject.addEventListener('play', onVideoPlay);
			self.playerObject.addEventListener('error', onError);
			self.playerObject.addEventListener('loadedmetadata', onLoadedData);
			// setTimeout(() => {
			//   self.playerObject.play();
			// }, 1000);

			// if (this.playerObject === undefined) {
			// this.playerObject.load(source).then(function (player) {
			//   self.seekPlayer(0, true, self.setBookMark);
			//   if (autoPlay) {
			//     setTimeout(() => {
			//       self.playerObject.play();
			//     }, 1000);
			//   }
			// }, function (reason) { });
			// this.bitMovieUnsubsribe = this.playerObject.load(source).then(function (player) {
			//   var container = $('.player-container');
			//   self.seekPlayer(0, true, self.setBookMark);
			//   if (autoPlay) {
			//     setTimeout(() => {
			//       self.playerObject.play();
			//     }, 1000);
			//   }
			//   if (container.height() <= playerHeight) {
			//     container.height(playerHeight);
			//   }
			//   self.playTimeOut = setTimeout(() => {
			//     if (self.playerObject !== undefined) {
			//       self.playerObject.play();
			//     }
			//   }, 1000);

			// }, function (reason) {
			//   // console.log(reason);
			// });

			// }, 1.5);
		}
	}

	seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {

		if (videoDuration) {
			if (setBookMark) {
				if ((this.playerObject.duration - this.bookmarkDuration) > 30) {
					this.playerObject.currentTime = this.bookmarkDuration;
				} else {
					this.playerObject.currentTime = this.playerObject.currentTime + duration;
				}
			}
		} else {
			this.playerObject.currentTime = this.playerObject.currentTime + duration;
		}

	}

	nextVideo() {
		this.setBookMark = false;
		this.watchMovie = false;
		for (let i = 0; i < this.assetGroupList.data.length; i++) {
			if (i == this.currentIndex) {
				const currentVideo = this.assetGroupList.data.filter((videoObj) => {
					return videoObj.path == this.videoPageData.path;
				});
				if (currentVideo.length > 0 && this.assetGroupList.data.length > i + 1) {
					this.getVideoDetails(this.assetGroupList.data[i + 1].path, i + 1);
				} else {
					this.getVideoDetails(this.assetGroupList.data[i].path, i);
				}
				break;
			}
		}
	}

	replayVideo() {
		// $('#premium-id').removeClass('premium-vod');
		// alert('loglog');
		// console.log('loglog');
		this.seconds = 5;
		this.mainloading = true;
		this.replay = true;
		// this.watchMovie = true;
		this.videoFinish = false;
		clearInterval(this.secondInterval);
		clearTimeout(this.secondsTimeout);
		this.getVideoDetails(this.videoPath, this.currentIndex);
	}

	// adjustPlayer() {
	//   const container = $('.player-container');

	//   /* extract constants for better readabilty */
	//   const lowerEdge = container.height();
	//   const switchToMinPlayerPos = lowerEdge - (window.innerHeight / 16);
	//   const currentScrollPos = document.body.scrollTop || document.documentElement.scrollTop;

	//   /* toggle the css-class responsible for the player moving to the lower right corner */
	//   if (currentScrollPos > switchToMinPlayerPos) {
	//     $('.player-switch').addClass('fixed-player');
	//     if (document.getElementById("pip-close")) {
	//       document.getElementById("pip-close").onclick = function () { closePip() };
	//     }
	//   } else {
	//     $('.player-switch').removeClass('fixed-player');
	//   }
	//   function closePip() {
	//     window.scroll(0, 0)
	//   }
	// }

	public getCastCrew(assetpath: string): void {
		const pageRequest = { "assetId": assetpath };
		let fiterString = "?filter=" + encodeURI(JSON.stringify(pageRequest));
		this.assetGroupService.getCastCrews(fiterString).subscribe(
			res => {
				if (res !== undefined && res.success == true) {
					this.castCrewData = res.data;
					this.cast = '';
					this.director = '';
					if (this.castCrewData !== null && this.castCrewData.data) {
						for (const castcrew of this.castCrewData.data) {
							if (castcrew.type == 'Director') {
								if (this.director === '') {
									this.director = ' ' + this.director + castcrew.firstName + ' ' + castcrew.lastName;;
								} else {
									this.director = this.director + ', ' + castcrew.firstName + ' ' + castcrew.lastName;;
								}
							} else if (castcrew.type == 'cast') {
								if (this.cast === '') {
									this.cast = ' ' + this.cast + castcrew.firstName + ' ' + castcrew.lastName;
								} else {
									this.cast = this.cast + ', ' + castcrew.firstName + ' ' + castcrew.lastName;
								}
							}
						}
					}
				}
			},
			err => {
				console.log(err);
			}
		);
	}

  public toggleFavourite(path: string, newValue?: boolean): void {
    let userId: any = this.userId;
    if (userId > 0) {
      if (this.geoStatus) {
        $('#favouriteloader').css({ "display": "block" });
        const pageRequest = { assetId: path };

        if (newValue === undefined)
          newValue = this.isUserLikes != 1;

        if (newValue) {
          this.assetGroupService.addTofavourite(pageRequest).subscribe(
            res => {
              if (res !== undefined && res.success === true) {
                this.isUserLikes = 1;
                this.successResponse(res.message);
                $('#favouriteloader').css({ "display": "none" });
              } else if (res !== undefined && res.success === false) {
                this.errMessageResponse(res.message);
              }
            },
            err => {
              console.log(err);
            }
          );
        } else {
          this.assetGroupService.removeTofavourite(pageRequest).subscribe(
            res => {
              if (res !== undefined && res.success === true) {
                this.isUserLikes = 0;
                this.successResponse(res.message);
                $('#favouriteloader').css({ "display": "none" });
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      }
    } else {
      	this.warningMessageResponse('Inicia sesión para agregarlo a tus favoritos');
    }
  }

	public addToWatchlist(): void {
		const pageRequest = {
			"userId": this.userId,
			"assetId": this.videoPageData.path
		};
		this.assetGroupService.addToWatchlist(pageRequest).subscribe(
			res => {
				if (res !== undefined && res.success == true) {
					this.videoPageData.watchedCount += 1;
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	public getGenreTitle(videopageData): string {
		var genreTitle = '';
		const genres = videopageData.genre;
		if (genres.length > 0) {
			for (let orientation of genres) {
				if (genreTitle != '') {
					genreTitle += ', ';
				}
				genreTitle += orientation.genreName;
			}
		} else {
			this.noGenre = true;
		}
		this.genreTitle = genreTitle;
		return this.genreTitle;
	}

	public goToHomePage(): void {
		this.headerService.reloadHeader.emit(true);
		this.router.navigate(['']);
	}

	ngOnDestroy(): void {
		if (this.isLoggedIn && this.videoPageData) {
			const pageRequest = {
				assetId: this.videoPageData.path,
			};
		}
		if (this.playerObject !== undefined) {
			this.playerObject.destroy();
		}
	}

	public facebookShare(e) {
		e.preventDefault();
		var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, 'facebook-popup', 'height=350,width=600');
		if (facebookWindow.focus) {
			facebookWindow.focus();

		}
		return false;
	}

	public twitterShare(e) {
		e.preventDefault();
		var twitterWindow = window.open('https://twitter.com/share?url=' + window.location.href, 'twitter-popup', 'height=350,width=600');
		if (twitterWindow.focus) { twitterWindow.focus(); }
		return false;
	}



	async goRent(event, type) {
		if (this.isLoggedIn) {
			if (type == 'TVOD') {
				$('#favouriteloader').css('display', 'block');
				if (!this.subScriptionData.length) {
					await this.getPremiumForSubscriptionList();
				}
				$('#favouriteloader').css('display', 'none');
				$('#payment-model').modal('show');
			} else if (type == 'SVOD') {
				var explode_dni = this.user.emailId;
				var dni = explode_dni.split('@');

				var tenant_hidden = this.tenant;
				var payment_type_hidden = 'SUB';
				var url_path_hidden = 'https://live.onstream.com.ar/';
				var client_id_hidden = this.user.id;
				var title_hidden = 'sidebar';
				var dni_hidden = dni[0];
				var unit_price_hidden = 100;

				var html = '<form action="https://onstream.com.ar/payment-subscription" method="post" id="formHiddenPlans" style="display:none;"><input type="hidden" id="tenant_hidden" name="tenant_hidden" value="' + tenant_hidden + '"><input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="' + payment_type_hidden + '"><input type="hidden" id="url_path_hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" id="client_id_hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" id="title_hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" id="dni_hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" id="unit_price_hidden" name="unit_price_hidden" value="' + unit_price_hidden + '"><button type="submit">ADQUIRIR</button></form>';
				$("#plansFormContainer").append(html);
				$("#formHiddenPlans").submit();
			}
		} else {
			localStorage.setItem('videoUrl', this.router.url)
			this.warningMessageResponse('Inicia sesión para ver este contenido');
		}
	}

	closeloader() {
		$('#favouriteloader').css({ "display": "none" });
	}

	getPremiumForSubscriptionList() {
		return new Promise((resolve, reject) => {
			this.subScriptionData = [];
			const subscriptionRequest = { "deviceTypeId": "1", "assetId": this.currentAssetPath };
			const subscriptionFiterString = "?filter=" + encodeURI(JSON.stringify(subscriptionRequest));
			this.assetGroupService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
				if (res != undefined && res.success == true) {
					const rentPlan = res.data.data;
					for (let plan of rentPlan) {
						if (plan.subscriptionType == 'TVOD') {
							this.subScriptionData.push(plan);
						}
					}
					resolve("ok");
				}
			})
		})
	}

	public gotoVideoPage(pagedata: PageData): void {
		if (pagedata.type.toLowerCase() == 'asset') {
			this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
		} else if (pagedata.type.toLowerCase() == 'asset_group') {
			this.router.navigate(['/season', pagedata.path]);
		}
	}

	gotoPlayerPage(pagedata) {
		let userId = this.user.id;
		if (this.getBrowserName() == 'edge') {
			this.warningMessageResponse('Para una mejor experiencia en su reproduccion, por favor utilizar el navegador Chrome');
			return
		}
		if (userId > 0) {
			if (this.isMobileDevice()) {
				$('#modal-web-or-app').modal('show');
				return;
			}
			if (pagedata.assetType.toLowerCase() == 'asset') {
				this.router.navigate(['/video-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
			} else if (pagedata.assetType.toLowerCase() == 'asset_group') {
				this.router.navigate(['/season', pagedata.path]);
			} else {
				this.router.navigate(['/video-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
			}
		} else {
			this.warningMessageResponse('Atención Debes iniciar sesión para poder reproducir este video');/*Inicia sesión para agregarlo a tus favoritos*/
		}
	}

	getBrowserName(): string {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf('edg') > -1:
				return 'edge';
			case agent.indexOf('opr') > -1 && !!(<any>window).opr:
				return 'opera';
			case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
				return 'chrome';
			case agent.indexOf('trident') > -1:
				return 'ie';
			case agent.indexOf('firefox') > -1:
				return 'firefox';
			case agent.indexOf('safari') > -1:
				return 'safari';
			default:
				return 'other';
		}
	}

	gotoTrailerPage(pagedata: PageData): void {
		if (this.getBrowserName() == 'edge') {
			this.warningMessageResponse('Para una mejor experiencia en su reproduccion, por favor utilizar el navegador Chrome');
			return
		}
		this.router.navigate(['/trailer-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
	}

	paymentmethod(event, type) {
		if (type == 'stripe') {
			$('#rent-list').modal('show');
		} else if (type == 'voucher') {
			$('#modal-vouchers').modal('show');
			this.checkout2(this.subScriptionData[0], event)
		} else if (type == 'mercadopago') {
			$('.main-loader-overlay').css('display', 'none');
			$('#favouriteloader').css('display', 'none');
		}
	}

	goToPlansPage() {
		const removeAccents = (str) => {
			return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		}
		var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');

		var url_path_hidden = removeAccents('https://live.onstream.com.ar/video/' + this.videoPageData.title.split(/\s/).join('') + '/' + this.videoPageData.path);
		var client_id_hidden = this.user.id;
		var title_hidden = this.videoPageData.title.split(/\s/).join('');
		var dni_hidden = dni[0];

		var html = '<form action="https://onstream.com.ar/payment-subscription" method="post" id="formHiddenPlans" style="display:none;"><input type="hidden"name="tenant_hidden" value="'+ this.tenant +'"><input type="hidden" name="payment_type_hidden" value="SUB"><input type="hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" name="unit_price_hidden" value="100"><button type="submit"></button></form>';
		$('#payment-model').append(html);
		setTimeout(function () {
			$("#formHiddenPlans").submit();
		}, 300);
	}

	checkout2(item, e) {
		let totalAmount = item.rate.toString();
		let totalAmountDecimal = totalAmount.replace(',', '.');

		//en pagina de checkout, al momento de checkout
		//paso 1: le digo a libreria que haga render en el div
		(<any>window).OP.renderFormInElement("voucher-oneplay");
		//paso 2: inicio intent de redeem y paso parametros obligatorios
		(<any>window).OP.openRedeemVoucherIntent({
			client_email: this.user.emailId,
			client_country: this.user.countryCode, //this is the value of the client`s registration on database 
			asset_value: totalAmountDecimal,
			asset_id: this.videoPageData.path, //this code could be either asset id value or the monetization plan id value
			client_id: this.user.id,
			client_platform: "web", //this is identify the platform, values: web,ios,android
		})
			.then(res => {
				const orderReq2 = {
					orderId: res.data.hash,
					assetId: this.videoPageData.path,
					userId: this.user.id,
					subscriptionId: item.path,
					isRecurring: 1,
					billingName: this.user.firstName,
					billingEmail: this.user.emailId,
					status: "Active",
					usageType: "Paid",
					isActive: 1,
					amount: item.rate,
					description: item.title,
					paymentMethod: "vouchers"
				};
				let generated_hash = CryptoJS.SHA256(environment.voucherkey + this.videoPageData.path + this.user.id + totalAmountDecimal).toString(CryptoJS.enc.Hex);
				if (generated_hash == res.data.hash) {
					this.assetGroupService.placeOrder(orderReq2).subscribe(
						apires => {
							if (apires !== undefined && apires.success === true) {
								let now = new Date();

								now.setHours(now.getHours() + 48);

								let year = now.getFullYear();
								let month = now.getMonth() + 1;
								let day = now.getDate();
								let hour = now.getHours();
								let minute = now.getMinutes();

								let formattedDate = `${day}/${month}/${year} ${hour}:${minute}hs`;

								this.successResponse(
									"Todo listo, disfruta de " + this.videoPageData.label +
									" hasta el " + formattedDate)
								// this.successResponse(successMessage
								// 	.replace(/<[^>]+>/g, ""));
								this.setSelectedAsset({
									currentAssetPath: this.videoPageData.path
								});
								this.isAssetSelected = true;
								$('#favouriteloader').css({ "display": "none" });
								this.ngOnInit();
							} else if (apires !== undefined && apires.success === false) {
								this.errMessageResponse(apires.message);
							}
						},
						err => {
							console.log(err);
						});
				}
				$('#modal-vouchers').modal('hide');
			})
			.catch(err => {
				$('#modal-vouchers').modal('hide');
				console.log(err)
				this.errMessageResponse(err.message)
			})
		$('#modal-vouchers').modal('show');
		e.preventDefault();
	}

	checkout4(item, e) {
		var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');


		$('#favouriteloader').css({ "display": "block" });
		let totalAmount_mp = item.rate.toString();
		var tenant_hidden = this.tenant;
		var payment_type_hidden = 'ALQ';
		var url_path_hidden = 'https://live.onstream.com.ar.com/video/' + stringToUrlFriendly(this.videoPageData.title) + '/' + this.videoPageData.path;
		var client_id_hidden = this.user.id;
		var title_hidden = item.title;
		var dni_hidden = dni[0];
		var unit_price_hidden = totalAmount_mp;

		var html = `
			<form action="https://onstream.com.ar/payment-tvod" method="post" id="formHidden" style="display:none;">
				<input type="hidden" id="tenant_hidden" name="tenant_hidden" value="${tenant_hidden}">
				<input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="${payment_type_hidden}">
				<input type="hidden" id="url_path_hidden" name="url_path_hidden" value="${url_path_hidden}">
				<input type="hidden" id="client_id_hidden" name="client_id_hidden" value="${client_id_hidden}">
				<input type="hidden" id="title_hidden" name="title_hidden" value="${title_hidden}">
				<input type="hidden" id="dni_hidden" name="dni_hidden" value="${dni_hidden}">
				<input type="hidden" id="unit_price_hidden" name="unit_price_hidden" value="${unit_price_hidden}">
				<button type="submit">TARJETA</button>
			</form>
		`;
		$("#container-mercadopagobtn").append(html);
		$("#formHidden").submit();
	}

	handlePasteInOtpInputs(event: ClipboardEvent): void {
		event.preventDefault();

		const container: HTMLElement = this.voucherOneplay.nativeElement;

		const clipboardData = event.clipboardData ? event.clipboardData.getData('text') : '';
		if (!clipboardData) {
			return;
		}

		const chars = clipboardData.split('');
		if (chars.length > 6) {
			return;
		}

		for (let i = 0; i < chars.length; i++) {
			const inputElement = container.querySelector(`#otp-field${i}`) as HTMLInputElement;
			if (inputElement) {
				inputElement.value = chars[i];
			}
		}
	}


	openApp() {
		const iOSAppStoreURL = 'https://apps.apple.com/bo/app/onstream/id6449293113';
		const androidPlayStoreURL = 'https://play.google.com/store/apps/details?id=com.oneplay.axion';

		const userAgent = window.navigator.userAgent;
		const isAndroid = userAgent.includes('Android');
		const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window['MSStream'];
	  
		if (isiOS) {
			// const appURLScheme = 'com.oneplay.filmity://' + this.router.url;
			// window.location.href = appURLScheme;
			setTimeout(() => {
				if (document.hasFocus())
					window.location.href = iOSAppStoreURL;
			}, 500);
		} else if (isAndroid) {
			// const appURLScheme = 'https://live.filmity.com/' + this.router.url;
			// window.location.href = appURLScheme;
			setTimeout(() => {
				window.location.href = androidPlayStoreURL;
			}, 500);
		}
	}

	playInWeb() {
		const pageData = this.videoPageData;
		if (pageData.assetType.toLowerCase() == 'asset') {
			this.router.navigate(['/video-page', pageData.title.split(/\s/).join(''), pageData.path]);
		} else if (pageData.assetType.toLowerCase() == 'asset_group') {
			this.router.navigate(['/season', pageData.path]);
		} else {
			this.router.navigate(['/video-page', pageData.title.split(/\s/).join(''), pageData.path]);
		}
	}
}
