import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import {
  Component,
  OnInit,
  NgZone,
  OnDestroy,
  Renderer2,
  Inject,
} from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { NavigationPage } from 'src/app/components/header/header.model';
import {
  PageCategoryData,
  PageCategory,
  PageData,
  AssetGroupClassification
} from '@home/model/pagecategory.model';
import {
  VideoPageData
} from '@video-page/asset-page.model';
import { HeaderService } from 'src/app/components/header/header.service';
import { ProfileService } from '@profile/service/profile.service';
import { HomeService } from '@home/services/home.service';
import { Carousal, carouselData } from '@home/model/carousal';
import { ServiceLocator } from '@base/constants/service-locator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VideoPageService } from '@video-page/asset-page.service';
import { Params, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: '../view/home.component.html',
  styleUrls: ['../css/home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  route: ActivatedRoute;

  public profileService: ProfileService;
  public carousalResponse: carouselData[] = [];
  public caroselVideoResponse: carouselData[] = [];
  videopageData: VideoPageData;
  showImage = false;
  videoImage = '';
  currentPage: any;
  carouselLength: any;

  constructor(private ngZone: NgZone,
    _activatedRoute: ActivatedRoute,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.profileService = ServiceLocator.injector.get(ProfileService);
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.homeService = ServiceLocator.injector.get(HomeService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
    this.route = _activatedRoute;
  }

  public contactform: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    mobile_number: new FormControl('', [Validators.required])
  });

  public PageCategoryParams = new URLSearchParams();

  pageCategoryData: PageCategoryData;
  headerService: HeaderService;
  public homeService: HomeService;
  public pageData: PageCategoryData;
  public navigation: string;
  public selectedGenreMap: {} = {};
  pagelist: PageData[];
  videopageService: VideoPageService;
  navigationData: NavigationPage[];
  public NavigationParams = new URLSearchParams();
  skip: number;
  params: any;
  videoSection: boolean;
  showHomeVideo: boolean;
  isPageCategory = false;
  onInitloader = true;
  title: any;
  isLoader = false;;

  watchlistIsLoaded: boolean = false;

  async ngOnInit() {
    await this.waitForToken();

    // Manage errors by querying the url
    this.route.queryParams.subscribe(params => {
      const error = params['error'];
      if (error) {
        this.router.navigate(['']);
        this.errMessageResponse(error);
      }
    });
    
    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() == $(document).height()) {
      }
    });
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    $(document).ready(function () {
      $('#lightSlider').lightSlider({
        gallery: true,
        item: 1,
        loop: false,
        slideMargin: 0,
        thumbItem: 24
      });
    });
    this.sliderScript();
    this.headerService.reloadHeader.emit(true);
    this.route.params.subscribe((params: Params) => {
      this.onInitloader = true;
      $('#loader').css({ "display": "none" });
      $('#carousalLoader').css({ "display": "none" });
      this.caroselVideoResponse = [];
      this.pageCategoryData = null;
      this.carousalResponse = null;
      this.showImage = false;
      this.videoImage = '';
      this.currentPage = params.name;
      $('#volume-off').hide();
      $('#volume-on').hide();
      $('#loader').css({ "display": "none" });
      this.rendrer.removeClass(this.document.body, 'transperent-layout');
      this.rendrer.removeClass(this.document.body, 'hide-header');
      this.rendrer.removeClass(this.document.body, 'hide-footer');
      $(document).ready(function () {
        $(this).scrollTop(0);
        window.scroll(0, 0);
      });
      this.navigationData = null;
      let filterNavigation: NavigationPage[];

      if (
        this.navigationData === null ||
        Object.keys(this.navigationData).length === 0
      ) {
        const pageRequest = {
          deviceTypeId: 1,
          langaugeCode: 1
        };
        this.headerService.getNavigation(pageRequest).subscribe(result => {
          if (result !== undefined && result.success == true) {
            this.navigationData = result.data.data;
            filterNavigation = this.navigationData.filter(
              res => res.title.split(' ').join('').toLocaleLowerCase() == params.name.split(' ').join('').toLocaleLowerCase()
            );
            this.showHomeVideo = false;
            this.setNavigationData(filterNavigation);
            return true;
          } else if (result !== undefined && result.success == false) {
          }
        }, err => {
          this.getnavigation(filterNavigation, params);
        }
        );
      } else if (
        this.navigationData !== null &&
        Object.keys(this.navigationData).length !== 0
      ) {
        if (params.name != "") {
          filterNavigation = this.navigationData.filter(
            res => res.title.split(' ').join('').toLocaleLowerCase() == params.name.split(' ').join('').toLocaleLowerCase()
          );
        } else {
          filterNavigation = this.navigationData.filter(
            res => res.title.split(' ').join('').toLocaleLowerCase() == 'home'
          );
        }
        this.caroselVideoResponse = [];
        this.carousalResponse = null;
        this.showHomeVideo = false;
        this.setNavigationData(filterNavigation);
        return true;
      }
    });
  }

  getnavigation(filterNavigation, params) {
    const pageRequest = {
      deviceTypeId: 1,
      langaugeCode: 1
    };
    this.headerService.getNavigation(pageRequest).subscribe(result => {
      if (result !== undefined && result.success == true) {
        this.navigationData = result.data;
        filterNavigation.data = this.navigationData.filter(
          res => res.title.split(' ').join('').toLocaleLowerCase() == params.name.split(' ').join('').toLocaleLowerCase()
        );
        this.showHomeVideo = false;
        this.setNavigationData(filterNavigation);
        return true;
      } else if (result !== undefined && result.success == false) {
      }
    })
  }

  public setNavigationData(navigate: NavigationPage[]): void {
    if (navigate !== undefined && navigate.length) {
      this.title = navigate[0].title;
      const requestParam = {
        path: navigate[0].path,
        type: navigate[0].type
      };
      this.params = requestParam;
      this.skip = 0;
      this.getCarousalResponse(requestParam);
      this.getPageCategorie(requestParam, false, 0);
      this.onInitloader = false;

      this.headerService.setActivePath.emit(navigate[0].title);
    }
  }

  refreshListingTooltip(): void {
  }

  public getCarousalResponse(requestParam): void {

    this.carousalResponse = null;
    this.caroselVideoResponse = null;
    const carousal = new Carousal();

    if (requestParam !== undefined) {
      this.navigation = requestParam;
    }

    const carouselRequest = {
      path: this.navigation['path'],
      type: this.navigation['type'],
      deviceTypeId: '1'
    };
    let fiterString = '?filter=' + encodeURI(JSON.stringify(carouselRequest));

    this.homeService.getMainCarousal(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {

          this.carousalResponse = res.data.filter(data => data.carouselType == 'Image' || data.carouselType == 'Video');
          this.carousalResponse.forEach(carousal => {
            carousal.filePath = this.setTenantBucket(carousal.filePath);
          })
          if (this.carousalResponse.length == 0) {
            this.rendrer.removeClass(this.document.body, 'transperent-layout');
          } else {
            this.rendrer.addClass(this.document.body, 'transperent-layout');
          }
          window.scroll(0, 0);
          this.onInitloader = false;
          this.ngZone.run(() => {
            setTimeout(() => {
              this.sliderScript();
              $('#lightSlider').lightSlider({
                gallery: true,
                locator: true,
                item: 1,
                loop: false,
                slideMargin: 0,
                thumbItem: 24
              });
            }, 500);
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getParallaxImage(image: string): string {
    return image;
  }

  refreshHorizontalCarousel(pageCategoryId: string): void {
    $('#page_category_list_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 21,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 4
        },
        1000: {
          items: 5
        }
      }
    });
  }

  refreshVerticalCarousel(pageCategoryId: string): void {
    $('#page_category_list_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 13,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 6
        }
      }
    });
  }

  refreshCustomCarousel(pageCategoryId: string): void {
    $("#page_category_list_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 15,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 3,
          nav: false
        },
        768: {
          items: 5
        },
        1000: {
          items: 6
        }
      }
    });
  }

  refreshListingCarousel(pageCategoryId: string): void {
    $('#page_category_listing_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 6
        }
      }
    });
  }

  refreshSubCategoryCarousel(pageCategoryId: string): void {
    $('#page_subcategory_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 3
        }
      }
    });
  }


  getWatchList(): void {
    this.watchlistIsLoaded = true;
    const requestWatchlistData = {
      "deviceTypeId": "1"
    };
    let fiterWatchlistString = "?filter=" + encodeURI(JSON.stringify(requestWatchlistData));
    this.profileService.getWatchlist(fiterWatchlistString).subscribe(res => {
      if (res !== undefined && res.success === true) {
        const watchedAssets = [];
        const serieTitles: string[] = [];
        res.data.every(asset => {
          if (watchedAssets.length === 7) return false;

          // Show only one episode for serie
          const serieTitle = asset.title.split(/[T,S][0-9][0-9]/gmi)[0];
          if (serieTitles.includes(serieTitle)) return true;
          serieTitles.push(serieTitle);

          // Set "streamsmas-content" in img paths
          asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
          asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
          asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
          asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

          // Set default duration if not populated
          if (asset.bookmarkDuration && !asset.duration) {
            asset.duration = 7200;
            asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
          }
          watchedAssets.push(asset);
          return true;
        });

        const row = new PageCategory()
        row.label = "Seguir viendo",
        row.data = watchedAssets;
        row.isDisplay = true;
        row.displayType = "Vertical";
        row.hideShowMoreButton = true
        row.path = "watchlist";
        row.is_loading = false;

        this.pageCategoryData.data.splice(1, 0, row);
        $('#page_category_list_' + row.path).trigger(
          'destroy.owl.carousel'
        );

        setTimeout(() => {
          this.refreshVerticalCarousel(row.path);
        }, 100);
      }
    },
      err => {
        console.log(err);
      });
  }

  public getPageCategorie(
    navigation: {},
    IsScrrol: boolean,
    skip: number
  ): void {

    if (navigation !== undefined) {
      this.navigation = navigation['path'];
    }

    const pageCategoryRequest = {
      deviceTypeId: '1',
      path: navigation['path'],
      type: navigation['type'],
      "langId": '1'
    };
    this.skip = this.skip + skip;
    let fiterString = '?filter=' + encodeURI(JSON.stringify(pageCategoryRequest)) + '&start=' + this.skip + '&showCount=' + 7;
    this.homeService.getPageCategories(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {

          res.data.forEach((category, index) => {

            // Sort assets randomly
            if (index > 2) {
              category.data = [...category.data].sort((a, b) => 0.5 - Math.random());
            }

            category.data.forEach(asset => {
              // Set "streamsmas-content" in img paths
              asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
              asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
              asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
              asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

              // Set default duration if not populated
              if (asset.bookmarkDuration && !asset.duration) {
                asset.duration = 7200;
                asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
              }
            });
          });


          const pageCategory: PageCategoryData = new PageCategoryData();
          if (IsScrrol) {
            if (res.data.length > 0) {
              res.data.forEach(record => {
                pageCategory.data = this.pageCategoryData.data.filter(
                  content => content.path == record.path
                );
              });
              if (pageCategory.data.length === 0) {
                res.data.forEach((record, index) => {
                  this.pageCategoryData.data.push(record);
                  if (record.genre.length > 0) {
                    this.getDefualtPageList(record.path);
                  } else {
                    const pageCategory: PageCategoryData = new PageCategoryData();
                    pageCategory.data = this.pageCategoryData.data.filter(
                      content => content.path === record.path
                    );
                    pageCategory.data[0].data = res.data[index].data;
                    pageCategory.data[0].isGenrePath = 0;
                    pageCategory.data[0].isPageCategory = true;

                    if (pageCategory.data[0].data.length > 1) {
                      pageCategory.data[0].isDisplay = true;
                    } else if (pageCategory.data[0].genre.length > 0) {
                      pageCategory.data[0].isDisplay = true;
                    } else if (pageCategory.data[0].assetGroupClassification.length > 0) {
                      pageCategory.data[0].isDisplay = true;
                    } else {
                      pageCategory.data[0].isDisplay = false;
                    }
                    $('#page_category_list_' + pageCategory.data[0].path).trigger(
                      'destroy.owl.carousel'
                    );

                    setTimeout(() => {
                      if (
                        pageCategory.data[0].genre.length != 0 ||
                        pageCategory.data[0].assetGroupClassification.length != 0
                      ) {
                        this.refreshSubCategoryCarousel(pageCategory.data[0].path);
                      }

                      if (
                        pageCategory.data[0].displayType == 'Horizontal' &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshHorizontalCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == 'Vertical' &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshVerticalCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == "Custom" &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshCustomCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == 'Listing' &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshListingCarousel(pageCategory.data[0].path);
                        this.refreshListingTooltip();
                      }
                    }, 50);
                  }
                });
              }
            }
            $('#loader').css({ "display": "none" });
          } else {
            this.pageCategoryData = res;
            this.pageCategoryData.data.forEach((pagelist, index) => {
              if (pagelist.genre.length > 0) {
                this.getDefualtPageList(pagelist.path);
              } else {
                const pageCategory: PageCategoryData = new PageCategoryData();
                pageCategory.data = this.pageCategoryData.data.filter(
                  content => content.path === pagelist.path
                );

                pageCategory.data[0].is_loading = true;
                pageCategory.data[0].data = pagelist.data;
                pageCategory.data[0].isGenrePath = 0;
                pageCategory.data[0].is_loading = false;
                pageCategory.data[0].isPageCategory = true;
                if (pageCategory.data[0].data.length > 1) {
                  pageCategory.data[0].isDisplay = true;
                } else if (pageCategory.data[0].genre.length > 0) {
                  pageCategory.data[0].isDisplay = true;
                } else if (pageCategory.data[0].assetGroupClassification.length > 0) {
                  pageCategory.data[0].isDisplay = true;
                } else {
                  pageCategory.data[0].isDisplay = false;
                }
                $('#page_category_list_' + pageCategory.data[0].path).trigger(
                  'destroy.owl.carousel'
                );

                setTimeout(() => {
                  if (
                    pageCategory.data[0].genre.length != 0 ||
                    pageCategory.data[0].assetGroupClassification.length != 0
                  ) {
                    this.refreshSubCategoryCarousel(pageCategory.data[0].path);
                  }

                  if (
                    pageCategory.data[0].displayType == 'Horizontal' &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshHorizontalCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == 'Vertical' &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshVerticalCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == "Custom" &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshCustomCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == 'Listing' &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshListingCarousel(pageCategory.data[0].path);
                    this.refreshListingTooltip();
                  }
                }, 50);
              }
            });
          }
          if (!this.watchlistIsLoaded)
            this.getWatchList();

        } else if (res !== undefined && !res.success) {
          console.log(res);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public gotoPremiumVideo(): void {
    this.router.navigate(['premium']);
  }

  public getDefualtPageList(path: string): void {

    const pageListRequest = { deviceTypeId: '1', path: path, type: 'page_category', genre: [], langId: '1' };

    $('#page_subcategory_' + path + ' li a').removeClass('active');

    $('#page_subcategory_item_' + path + ' a').addClass('active');
    const pageCategory: PageCategoryData = new PageCategoryData();
    pageCategory.data = this.pageCategoryData.data.filter(
      content => content.path === path
    );
    pageCategory.data[0].is_loading = true;
    let fiterString = '?filter=' + encodeURI(JSON.stringify(pageListRequest)) + '&start=0&limit=7';
    this.homeService.getDefualtPageList(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          pageCategory.data = this.pageCategoryData.data.filter(
            content => content.path == path
          );
          pageCategory.data[0].isGenrePath = 0;
          pageCategory.data[0].data = res.data[0].data;
          pageCategory.data[0].isPageCategory = true;
          if (pageCategory.data[0].data.length > 1) {
            pageCategory.data[0].isDisplay = true;
          } else {
            pageCategory.data[0].isDisplay = false;
          }
          if (pageCategory.data[0].randomPageCategory) {
            pageCategory.data[0].data = pageCategory.data[0].data.sort(() => Math.random() - 0.5)
          }
          pageCategory.data[0].is_loading = false;
          $('#page_category_list_' + pageCategory.data[0].path).trigger(
            'destroy.owl.carousel'
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length != 0 ||
              pageCategory.data[0].assetGroupClassification.length != 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType == 'Horizontal' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Vertical' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Listing' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  public getGenrePageList(genrepath: string, path: string): void {
    const genredataRequest = {
      'path': path,
      'deviceTypeId': '1',
      'genre': [genrepath],
      'langId': '1'
    };
    let genreFiterString = '?filter=' + encodeURI(JSON.stringify(genredataRequest)) + '&start=0&limit=7';

    $('#page_subcategory_' + path + ' li a').removeClass('active');

    $('#page_subcategory_item_' + path + '_' + genrepath + ' a').addClass(
      'active'
    );

    const pageCategory: PageCategoryData = new PageCategoryData();
    pageCategory.data = this.pageCategoryData.data.filter(
      content => content.path == path
    );
    pageCategory.data[0].isGenrePath = genrepath;
    pageCategory.data[0].is_loading = true;

    this.homeService.getDefualtPageList(genreFiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          pageCategory.data[0].isPageCategory = false;
          pageCategory.data[0].data = res.data[0].data;
          pageCategory.data[0].is_loading = false;
          $('#page_category_list_' + pageCategory.data[0].path).trigger(
            'destroy.owl.carousel'
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length !== 0 ||
              pageCategory.data[0].assetGroupClassification.length !== 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType === 'Horizontal' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === 'Vertical' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === 'Listing' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getAssetGroupList(assetpath: AssetGroupClassification, path: string): void {
    const pageListRequest = {
      deviceTypeId: '1',
      assetGroupClassification: assetpath,
      path: path,
      limit: 7,
      langId: '1'
    };

    $('#page_subcategory_' + path + ' li a').removeClass('active');

    $('#page_subcategory_item_' + path + '_' + assetpath + ' a').addClass(
      'active'
    );

    this.homeService.getDefualtPageList(pageListRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          const pageCategory: PageCategoryData = new PageCategoryData();
          pageCategory.data = this.pageCategoryData.data.filter(
            content => content.path === path
          );
          pageCategory.data[0].data = res.data[0].data;
          $('#page_category_list_' + pageCategory.data[0].path).trigger(
            'destroy.owl.carousel'
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length != 0 ||
              pageCategory.data[0].assetGroupClassification.length != 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType == 'Horizontal' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Vertical' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Listing' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  gotoAsset(asset) {
    if (asset.clickThrough != null && asset.clickThrough != '') {
      window.open(asset.clickThrough, '_blank');
    } else if (asset.type == 'asset') {
      const title = asset.title;
      this.router.navigate(['/video', title.replace(/\s/g, ''), asset.path]);
    } else if (asset.type == 'asset_group') {
      this.router.navigate(['/season', asset.path]);
    }
  }
  gotoCarouselAsset(asset) {
    this.router.navigate([asset.clickThrough]);
  }

  public gotoVideoPage(pagedata: PageData, carousel: carouselData): void {
    if (carousel !== null) {
      if (carousel.type === 'asset' && carousel.path !== null) {
      } else if (carousel.type === 'asset_group') {
        this.router.navigate(['/season', carousel.path]);
      }
    } else {
      if (pagedata.type === 'asset') {
        this.router.navigate([
          '/video',
          pagedata.title.split(/\s/).join(''),
          pagedata.path
        ]);
      } else if (pagedata.type === 'asset_group') {
        this.router.navigate(['/season', pagedata.path]);
      } else {
        this.router.navigate(['/video', pagedata.title.split(/\s/).join('').toLowerCase(), pagedata.path]);
      }
    }
  }

  public openGenresListing(path: string, genrePath): void {
    const myurl = '/geners';
    let param = {
      navigationpath: this.params.path,
      path: path
    };
    if (genrePath != 0) {
      this.router.navigate([myurl, param['navigationpath'], param['path'], genrePath]);
    } else {
      this.router.navigate([myurl, param['navigationpath'], param['path']]);
    }
  }

  public bindPageListing(pagedata: PageData[]): PageData[] {
    this.pagelist = null;
    if (pagedata.length !== 0) {
      pagedata.forEach((data, index) => {
        if (index !== 0) {
          if (this.pagelist != null) {
            this.pagelist = this.pagelist.concat(data);
          } else {
            this.pagelist = [];
            this.pagelist = this.pagelist.concat(data);
          }
        }
      });
    }

    return this.pagelist;
  }

  public addTofavourite(assetPath: string, pagecategory, imagenumber, index): void {
    if (
      this.user !== undefined &&
      Object.keys(this.user).length !== 0
    ) {
      const pageRequest = {
        assetId: assetPath
      };
      this.videopageService.addTofavourite(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            const indexofCategory = this.pageCategoryData.data.findIndex((categoryIndex) => {
              return categoryIndex.path == pagecategory.path;
            });
            if (imagenumber == 1) {
              return this.pageCategoryData.data[indexofCategory].data[0].isUserLikes = 1;
            } else if (imagenumber == 6) {
              let indexData = index + 1;
              return this.pageCategoryData.data[indexofCategory].data[indexData].isUserLikes = 1;
            }
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse('Inicia sesión para agregarlo a tus favoritos');
    }
  }

  public removeTofavourite(assetPath: string, pagecategory, imagenumber, index): void {
    if (
      this.user !== undefined &&
      Object.keys(this.user).length !== 0
    ) {
      const pageRequest = {
        assetId: assetPath
      };
      this.videopageService.removeTofavourite(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            const indexofCategory = this.pageCategoryData.data.findIndex((categoryIndex) => {
              return categoryIndex.path == pagecategory.path;
            });
            if (imagenumber == 1) {
              return this.pageCategoryData.data[indexofCategory].data[0].isUserLikes = 0;
            } else if (imagenumber == 6) {
              let indexData = index + 1;
              return this.pageCategoryData.data[indexofCategory].data[indexData].isUserLikes = 0;
            }
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse('Please Login To Add To Favorites');
    }
  }

  onScroll() {
    if (this.params !== undefined) {
      if ($(window).scrollTop() + $(window).height() >= 700) {
        $('#mainLoader').css({ "display": "none" });
        $('#carousalLoader').css({ "display": "none" });
        $('#loader').css({ "display": "block" });
        this.getPageCategorie(this.params, true, 2);
      }
    }
  }


  shortCarouselVideo(caroselVideoResponse) {
    var self = this;
    this.showHomeVideo = true;
    this.videopageData = null;
    var carouselVideoPath = 0;
    carouselVideoPath = caroselVideoResponse.path;

    const assetDetailRequest = { 'deviceTypeId': '1' };
    const assetDetailFiterString = '?filter=' + encodeURI(JSON.stringify(assetDetailRequest));
    this.videopageService.getAssetDetails(carouselVideoPath, assetDetailFiterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.videopageData = res.data[0];
          $('#volume-off').hide();
          $('#volume-on').hide();
          if (res.data.length > 0) {
            $('#volume-off').hide();
            $('#volume-on').hide();
            this.showImage = true;
            this.showHomeVideo = true;
          }
        } else {
          $('#volume-off').hide();
          $('#volume-on').hide();
          this.showImage = true;
          this.showHomeVideo = true;
        }
        //     })
        // }
      }, err => {
        $('#volume-off').hide();
        $('#volume-on').hide();
        this.carouselLength = false;
        this.showImage = true;
        this.showHomeVideo = true;
      });
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
  }

  sliderScript() {
    $(document).ready(function () {
      $('.homeSlider').owlCarousel({
        loop: true,
        margin: 21,
        nav: true,
        navText: [
          '<img src="./assets/images/prev-arrow.svg">',
          '<img src="./assets/images/next-arrow.svg">'
        ],
        autoplay: true,
        autoHeight: false,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1,
            nav: false
          },
          768: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
    });
  }
  checkIsLive(startDate, endDate, timeZone) {
    if (timeZone == null) {
      timeZone = "-5:00";
    }
    let offset = timeZone;

    let [h, m] = offset.split(':');

    let hours1 = Number.parseInt(h);
    let minutes1 = Number.parseInt(m);

    let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);

    let localStartDate = moment(moment.utc(startDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
    let localEndDate = moment(moment.utc(endDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
    if (moment().isBetween(localStartDate, localEndDate)) {
      return true;
    } else {
      return false;
    }
  }

}


