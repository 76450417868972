import { BaseModule } from '@base/module/base.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { HeaderService } from './header.service';
import { LoaderModule } from '../../components/loader/loader.module';

@NgModule({
    declarations: [HeaderComponent],
    imports: [CommonModule, RouterModule, BaseModule, LoaderModule],
    exports: [HeaderComponent],
    providers: [HeaderService]
})
export class HeaderModule { }
