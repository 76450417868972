import { environment } from '@environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceLocator } from '@base/constants/service-locator';
import { ProfileData } from '@profile/model/profile.model';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BaseComponent implements OnInit{
  private static _token: string;
  private static _isLoggedIn: boolean = false;
  private static _user: ProfileData = null;
  private static _userId: string = null;
  private static _tenant: string = '39';

  public router: Router;
  public toastr: ToastrService;
  public imagePrefix = environment.imagePrefix;

  constructor() {
    this.router = ServiceLocator.injector.get(Router);
    this.toastr = ServiceLocator.injector.get(ToastrService);
  }
  ngOnInit(): void {
    if (!this.userId)
    BaseComponent.fillAuthData();
  }

  public get tenant(): string {
    return BaseComponent._tenant;
  }

  public static get token(): string {
    return BaseComponent._token;
  }

  public get token(): string {
    return BaseComponent._token;
  }

  public get isLoggedIn(): boolean {
    return BaseComponent._isLoggedIn;
  }

  public get userId(): string {
    return BaseComponent._userId;
  }

  public get user(): ProfileData {
    return BaseComponent._user;
  }

  // LocalStorage Actions
  public static fillAuthData() {
    const userToken = localStorage.getItem('user_token');
    const guestToken = localStorage.getItem('guest_token');
    
    BaseComponent._userId = localStorage.getItem('user_id');
    BaseComponent._token = userToken || guestToken;
    BaseComponent._isLoggedIn = !!userToken;

    return {
      isLoggedIn: BaseComponent._isLoggedIn,
      token: userToken || guestToken
    };
  }

  public static clearAuthData() {
    // excecuted when is signing out
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_token');
    localStorage.removeItem('guest_token');

    BaseComponent._userId = null;
    BaseComponent._token = null;
    BaseComponent._isLoggedIn = false;
  }

  public static setGuestToken(token: string) {
    token = `Bearer ${token}`;
    localStorage.setItem('guest_token', token);
    BaseComponent._token = token;
  }

  public static setUserToken(token: string) {
    token = `Bearer ${token}`;
    localStorage.setItem('user_token', token);
    BaseComponent._token = token;
    BaseComponent._isLoggedIn = true;
  }

  public static setUser(user) {
    localStorage.setItem('user_id', user.id);
    BaseComponent._user = { ...user } as ProfileData;
  }
  public setTenantBucket(path: string): string {
		if (!path) return path;
		if (path.includes("https://oneplay-content")) {
			return path.replace("https://oneplay-content", "https://streamsmas-content");
		} else if (path.includes("https://oneplaycontent")) {
      return path.replace("https://oneplaycontent", "https://streamsmascontent");
    }
		  return path;
	}

  public waitForToken() {
    return new Promise((res, rej) => {
      let waitTillGuestTokenInterval = null
      waitTillGuestTokenInterval = setInterval(() => {
        if (BaseComponent._token) {
          clearInterval(waitTillGuestTokenInterval);
          res(0)
        }
      }, 500);
    })
  }

  public isMobileDevice() {
		return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
	}

  // Toast Functions
  public successResponse(message?: string) {
    this.toastr.success(message, '');
  }

  public errMessageResponse(message?: string) {
    this.toastr.error(message, '');
  }

  public warningMessageResponse(message?: string) {
    this.toastr.warning(message, '');
  }
}
