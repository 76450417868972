import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileService } from '@profile/service/profile.service';
import { FavoritesComponent } from '@favorite/component/favorites.component';
import { VideoPageService } from '@video-page/asset-page.service';
import { LoaderModule } from 'src/app/components/loader/loader.module';


@NgModule({
  declarations: [FavoritesComponent],
  imports: [
    CommonModule,
    LoaderModule,
  ],
  providers: [ProfileService, VideoPageService],
  exports: [FavoritesComponent]
})
export class FavoritesModule { }
