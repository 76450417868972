import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FootergenreComponent } from '../component/footergenre.component';
import { FooterService } from '@footer/services/footer.service';
import { LoaderModule } from 'src/app/components/loader/loader.module';



@NgModule({
  declarations: [FootergenreComponent],
  imports: [
    CommonModule,
    LoaderModule
  ],
  providers: [FooterService],
  exports: [FootergenreComponent]
})
export class FootergenreModule { }
