import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { FooterService } from '@footer/services/footer.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { FooterData } from '@footer/model/footer.model';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: '../view/footer.component.html',
  styleUrls: ['../css/footer.component.css']
})

export class FooterComponent extends BaseComponent implements OnInit {

  footerService: FooterService;
  footerData: FooterData;
  footerLinksData: any;
  footerLinks: any;
  is_loading: boolean;
  year: any = new Date().getFullYear();
  disClaimerFlag = '';
  logo: string;

  public contactUsForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    mobile: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(6) , Validators.pattern("^[0-9]*$")]),
    message: new FormControl('', [Validators.required])
  });

  constructor(private cookieService: CookieService) {
    super();
    this.footerService = ServiceLocator.injector.get(FooterService);
  }

  async ngOnInit() {
    await this.waitForToken();

    this.disClaimerFlag = this.cookieService.get('readDisclaimer');
    this.footerService.reloadCookies.subscribe(res => {
      if (res) {
        this.disClaimerFlag = '';
      }
    });
    this.footerService.reloadFooter.subscribe(res => {
      if(res) {
        this.logo = res;
      }
    })
    this.logo = this.logo;
    setTimeout(() => {
      this.getFooterGeners();
      this.getFooterLinksData();
    }, 2000);

  }

  public getFooterGeners(): void {
    // const pageRequest = {
    //   device_type: 'WEB',
    // };
    this.footerService.getFooterGenres().subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.footerData = res;
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  public sendContact(contactform: FormGroup): void {
    this.is_loading = true;
    const pageRequest = {
      fullName: contactform.value['name'],
      email: contactform.value['email'],
      mobile: contactform.value['mobile'],
      message: contactform.value['message']
    };
    // if(!contactform.value['name'] || !contactform.value['email'] || !contactform.value['mobile'] || !contactform.value['massage'])
    // {
    //   this.errMessageResponse("Por favor complete los detalles");
    //   this.is_loading = false;
    // }
    // else
    // {
      this.footerService.sendContactUs(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            this.contactUsForm.reset();
            $('#contact-form').modal('hide');
            this.is_loading = false;
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        },
        err => {
          console.log(err);
        }
      );
    // }
  }

  public getFooterLinksData(): void {
    // const pageRequest = {
    //   device_type: '2',
    // };
    this.footerService.getFooterLinks().subscribe(
      res => {
        if (res !== undefined) {
          this.footerLinksData = res;
          this.footerLinks = res.data.data;
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  public goToFooterLinkData(footerlink: string, path: string): void {
    // if (footerlink === 'About Us') {
      // this.router.navigate(['about-us', path]);
      window.open(window.location.origin + '/footer' + '/'+ footerlink.split(" ").join("") +'/' + path, '_blank');
    // } 
    // else if (footerlink === 'Terms Of Use') {
    //   // this.router.navigate(['terms-of-use', path]);
    //   window.open(window.location.origin + '/terms-of-use/' + path, '_blank');
    // } else if (footerlink === 'Privacy Policy') {
    //   window.open(window.location.origin + '/privacy-policy/' + path, '_blank');
    //   // this.router.navigate(['privacy-policy', path]);
    // }
  }

  public openGenresListing(data): void {
    this.router.navigate(['/category', data.title.split(/\s/).join('').toLowerCase(), data.path]);
  }

  setcookie() {
    this.cookieService.set('readDisclaimer', 'true' );
    this.disClaimerFlag = this.cookieService.get('readDisclaimer');
  }

}
