import { environment } from '@environments/environment';
import { BaseComponent } from '@base/component/base.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as THEOplayer from '../../assets/js/TheoPlayer/THEOplayer.js';
import { addBookmark, getAssetDetails, getAssetPreplay } from '../api/assets.js';
import { attachABRResetLogic, languages, getDRM } from 'src/utils/theoPlayer.js';

declare var $: any;
@Component({
  selector: 'app-video-page-player',
  templateUrl: './video-page-player.component.html',
  styleUrls: ['./video-page-player.component.css']
})
export class VideoPagePlayerComponent extends BaseComponent implements OnInit, OnDestroy {
  assetId: string;
  assetTitle: string
  assetData = null;
  videoType: "movie" | "trailer" | "live";
  bookmarkDuration: number;
  setBookmark: boolean;
  isGeolocationAllowed: boolean = true;
  playerObject: any;
  preplayParameters;
  addBookmarkInterval: NodeJS.Timer;
  isLoading: boolean = false;

  route: ActivatedRoute;
  routePath: string;

  constructor(activateRoute: ActivatedRoute, private location: Location) {
    super();
    this.route = activateRoute;
    this.routePath = this.route.snapshot.url[0].path;
  }

  async ngOnInit() {
    (this.playerObject && this.playerObject.destroy) && this.playerObject.destroy();
    this.playerObject = undefined;
    this.assetData = null;

    if (!this.checkAvailableIosVersion()) return;

    this.route.params.subscribe(params => {
      this.assetId = params.id;
      this.assetTitle = params.title;
    });

    this.videoType = this.routePath === "trailer-page" ? "trailer" : "movie";
    this.setBookmark = this.videoType === "movie";

    // Validate authentication
    if (!this.isLoggedIn && this.videoType !== "trailer") {
      this.goToAssetPage();
      return;
    }

    await this.getAssetData();

    // Validate authorization
    if (!this.assetData.casUserWatchAsset && this.videoType !== "trailer") {
      this.goToAssetPage();
      return;
    }

    if (this.isGeolocationAllowed)
      this.showVideo();
  }

  checkAvailableIosVersion() {
    const split1 = navigator.userAgent.split("iPhone OS ");
    const split2 = (split1 && split1.length > 1) ? split1[1].split("_") : undefined;
    const iosVersion = (split2 && split2.length > 0) ? split2[0] : undefined;
    const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('CriOS');
    if (!iosVersion || isSafari) return true;
    if (Number(iosVersion) <= 12) {
      $("#ios-version-not-allowed-modal").modal("show");
      $("#pip-close").hide();
      return false;
    }
    return true;
  }

  goToAssetPage() {
    if (this.assetTitle)
      this.router.navigate(['/video', this.assetTitle.split(/\s/).join(''), this.assetId]);
    else
      this.location.back();
  }

  async getAssetData() {
    const response = await getAssetDetails(this.assetId);
    this.assetData = response.data[0];
    this.bookmarkDuration = Number(this.assetData.bookmarkDuration) || 0;
    this.isGeolocationAllowed = !!response.data[0].geoStatus;
    if (this.assetData.contentType === "Live")
      this.videoType = "live";
    this.preplayParameters = await getAssetPreplay({ 
      assetId: this.videoType === "trailer" ? this.assetData.mappedtrailer : this.assetId,
      drm: getDRM()
    });
  }

  showVideo() {
    const element = document.querySelector('.theoplayer-container') as HTMLElement;
    this.playerObject = new THEOplayer.Player(element, {
      libraryLocation: "src/assets/js/TheoPlayer/",
      license: environment.theoPlayer,
      // mutedAutoplay: 'all',
      ui: {
        language: 'esp',
        languages: languages,
        fluid: true,
      },
      cast: {
        chromecast: {
        }
      }
    });
    // this.playerObject.autoplay = true;

    if (this.videoType === "trailer") {
      this.playerObject.source = {
        sources: {
          integration: 'verizon-media',
          id: this.preplayParameters.data.preplay.cid,
          preplayParameters: this.preplayParameters.data.preplay,
          assetType: 'asset',
          contentProtected: true
        }
      }

      const onAddTrack = () => {
        // Set the initial quality value to the lowest one
        this.playerObject.videoTracks[0].targetQuality = this.playerObject.videoTracks[0].qualities[0];
        this.playerObject.addEventListener('progress', () => attachABRResetLogic(this.playerObject));
      }
      this.playerObject.videoTracks.addEventListener('addtrack', onAddTrack);
      return;
    }

    const hasCid = !!this.preplayParameters.data.preplay.cid;
    this.playerObject.source = {
      sources: {
        integration: 'verizon-media',
        id: hasCid
          ? (this.preplayParameters.data.preplay.cid)
          : ({
            userId: this.preplayParameters.data.preplay.oid,
            externalId: this.preplayParameters.data.preplay.eid
          }),
        preplayParameters: this.preplayParameters.data.preplay,
        assetType: hasCid ? "asset" : "channel",
        contentProtected: true
      }
    }

    const onAddTrack = () => {
      const highQualities = this.playerObject.videoTracks[0].qualities.filter(
        quality => quality.height == 1080
      );
      const quality = highQualities.length
        ? this.playerObject.videoTracks[0].qualities[0]
        : null;

      // start with the highest quality possible
      this.playerObject.videoTracks[0].targetQuality = quality;
    }

    const onTimePaused = async () => {
      clearInterval(this.addBookmarkInterval);
      if (this.setBookmark) {
        await this.addBookmark();
      }
    };

    const onVideoPlay = () => {
      if (this.setBookmark) {
        this.addBookmarkInterval = setInterval(async () => {
          await this.addBookmark();
        }, 30000);
      }
    };

    const onLoadedData = () => {
      this.playerObject.currentTime = this.bookmarkDuration;
    }

    const onError = () => { console.log("error") };

    // Se comenta momentaneamente la siguiente llamada por problemas en el reproductor de video
    // this.playerObject.videoTracks.addEventListener('addtrack', onAddTrack);
    
    if (this.videoType === "movie") {
      this.playerObject.addEventListener('loadedmetadata', onLoadedData);
      this.playerObject.addEventListener('pause', onTimePaused);
      this.playerObject.addEventListener('play', onVideoPlay);
      this.playerObject.addEventListener('error', onError);
    }
  }

  public async addBookmark() {
    await addBookmark({
      assetId: this.assetId,
      duration: this.playerObject.currentTime
    });
  };

  async ngOnDestroy() {
    if (this.isLoggedIn && this.setBookmark && this.isGeolocationAllowed)
      await this.addBookmark();
  }
}