import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Renderer2, Inject, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { VideoPageService } from '@video-page/asset-page.service';
import { AssetGroupDetailService } from '@asset-detail/services/asset-group-details.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ServiceLocator } from '@base/constants/service-locator';
import { Location } from '@angular/common';
import {
	VideoPage,
	VideoPageData,
	CastCrews
} from '@video-page/asset-page.model';
import { PageData } from '@home/model/pagecategory.model';
import { HeaderService } from "src/app/components/header/header.service";
import * as _ from 'underscore';
import { environment } from '@environments/environment';
import { error } from 'protractor';
import * as moment from 'moment';
import 'moment/locale/es';
import CryptoJS from 'crypto-js';
import { stringToUrlFriendly } from 'src/utils/strings';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var $: any;
@Component({
	selector: 'app-asset-page',
	templateUrl: './asset-page.component.html',
	styleUrls: ['./asset-page.component.css']
})
export class VideoPageComponent extends BaseComponent implements OnInit, OnDestroy {
	@ViewChild('voucherOneplay', { static: false }) voucherOneplay: ElementRef;

	public videopageService: VideoPageService;
	assetGroupService: AssetGroupDetailService;
	route: ActivatedRoute;
	assetPath: string;
	genrepath: any = [];
	primaryNavigatiopath: any = [];
	menuCategotypath: any = [];
	videopageData: VideoPageData;
	videodata: VideoPage;
	relatedVideoData: any;
	suggestedvideos: any;
	playerVisible: boolean;
	videoTitle = '';
	public videoURL: string;
	public videoPoster: string;
	playerObject: any;
	castcrewData: CastCrews;
	director: string;
	cast: string;
	subScriptionData = [];
	handler: any;
	isPremium: boolean;
	casUserWatchAsset: boolean;
	rented: boolean;
	csaiAdServer: string;

	addedToWatchList: any = 0;
	duration: any;
	bookmark_duration: any;
	progressBarBookmarkWidth: any;
	remainingMinutes: any;
	headerService: HeaderService;
	trailerPlayBackUrl: string;
	isShowVideo: boolean = false;
	isShowImage: boolean = false;
	watchMovie: boolean = false;
	setBookMark: boolean = false;
	same_for_all_geo_zone: boolean = false;
	showVideoError: boolean = false;
	geoStatus: boolean = false;
	user_can_see_asset: boolean = false;
	isIncreaseCount: boolean = false;
	showButton: boolean;
	playTimeOut: any;
	bitMovieUnsubsribe: any;
	noGenre = false;
	isUserLikes: any;
	showTrailerWithoutPayment = false;

	SelectedPath: any;
	genreTitle = '';
	videoFinish: any;
	seconds = 5;
	replay: any;
	secondInterval: any;
	secondsTimeout: any;
	clickOntrailer = false;
	promoCode: any;
	remainingSwaps: any;
	paymentId: string;

	noRelatedData = false;

	constructor(activateRoute: ActivatedRoute,
		private rendrer: Renderer2,
		private cookieService: CookieService,
		@Inject(DOCUMENT) private document: Document,
		private http: HttpClient,
		private location: Location) {
		super();
		this.videopageService = ServiceLocator.injector.get(VideoPageService);
		this.assetGroupService = ServiceLocator.injector.get(AssetGroupDetailService);
		this.headerService = ServiceLocator.injector.get(HeaderService);
		this.route = activateRoute;
	}

	public reloadedVideoPlayer(): void { }

	async ngOnInit() {
		await this.waitForToken();

		this.rendrer.removeClass(this.document.body, 'transperent-layout');
		this.rendrer.removeClass(this.document.body, 'hide-header');
		this.rendrer.removeClass(this.document.body, 'hide-footer');
		this.headerService.reloadHeader.emit(true);
		this.watchMovie = false;
		this.isShowVideo = false;
		$(document).ready(function () {
			$(this).scrollTop(0);
		});
		moment.locale('es');

		this.route.queryParams.subscribe(params => {
			this.paymentId = params['payment_id'];
		});

		this.route.params.subscribe((params: Params) => {
			this.videoFinish = false;
			this.replay = false;
			this.clickOntrailer = false;
			this.seconds = 5;
			this.csaiAdServer = '';
			this.videopageData = null;
			this.suggestedvideos = null;
			this.relatedVideoData = null;
			this.subScriptionData = [];
			this.showTrailerWithoutPayment = false;
			this.genreTitle = '';
			this.videoURL = '';
			this.addedToWatchList = 0;
			if (this.videopageData != null) {
				this.videopageData[0].playbackUrl = '';
				this.videopageData[0].trailerPlayBackUrl = '';
				this.videopageData[0].horizontalFilePath = '';
			}
			this.trailerPlayBackUrl = '';
			this.videoPoster = '';
			this.playerObject = undefined;
			this.casUserWatchAsset = false;
			
			window.scroll(0, 0);
			this.assetPath = params['path'];
			const assetDetailRequest = { deviceTypeId: 1 };
			// const clearCacheParams = clearCache
			//   ? `&randomParam=${Math.floor(Math.random() * 99) }`
			//   : "";
			const clearCacheParams = `&randomParam=${Math.floor(Math.random() * 99)}`;
			const assetDetailFiterString = "?filter=" + encodeURI(JSON.stringify(assetDetailRequest));
			this.videopageService.getAssetDetails(this.assetPath, assetDetailFiterString + clearCacheParams).subscribe(
				res => {
					if (res !== undefined && res.success == true) {
						//console.log({res})
						const lowerCaseTitle = res.data[0].title.toLowerCase().trim();
						if (res.data[0].assetGroup) {
							res.data[0].assetGroup.forEach(group => {

								if (lowerCaseTitle.includes(group.assetGroupName.toLowerCase())) {
									//console.log("encontramos serie")
									$('#posterImage').css({ "display": "none" });
									$('#favouriteloader').css({ "display": "block" });
									this.goToSeriePage(group.assetGroupId, this.assetPath);
									return;
								}
							});
						}
						this.videodata = res;
						this.videopageData = res.data;
						this.addedToWatchList = this.videopageData[0].isUserWatched;
						this.videoURL = this.videopageData[0].playbackUrl;
						this.videoPoster = this.videopageData[0].horizontalFilePath;
						this.videoPoster = this.setTenantBucket(this.videoPoster);
						this.duration = this.videopageData[0].duration;
						this.duration = this.duration ? this.hmsToSecondsOnly(this.duration) : 7200;
						this.bookmark_duration = this.videopageData[0].bookmarkDuration;
						this.progressBarBookmarkWidth = ((this.bookmark_duration * 100) / this.duration).toString() + "%"
						this.remainingMinutes = Math.floor((this.duration - this.bookmark_duration) / 60)
						this.trailerPlayBackUrl = this.videopageData[0].mapTrailerVideo;
						this.isUserLikes = this.videopageData[0].isUserLikes;
						this.isPremium = this.videopageData[0].isPremium;
						this.casUserWatchAsset = this.videopageData[0].casUserWatchAsset;
						this.csaiAdServer = this.videopageData[0].csaiAdServer;
						this.geoStatus = this.videopageData[0].geoStatus;
						setTimeout(() => {
							$('#trailer_carousel_list').trigger('destroy.owl.carousel');
							setTimeout(() => {
								this.refreshHorizontalCarousel('trailer_carousel_list');
							}, 50);
						}, 300);
						this.getRelatedSuggestedVideos();
						this.getCastCrew(this.videopageData[0].path);
						this.getGenreTitle(this.videopageData);
					}
				},
				err => {
					console.log(err);
				}
			);
		});

		$(document).ready(function () {
			$('.vid-description a').click(function () {
				window.open(this.href);
				return false;
			});
		});

		if (this.paymentId) {
			const url = 'https://api.oneplay.net/processes/payments/axion/webhook_manual.php'
			const headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			});
			
			const body = new URLSearchParams();
			body.set('payment_id', this.paymentId);

			this.http.post(url, body.toString(), { headers: headers,  responseType: 'text' }).subscribe(
			data => {
				const urlWithoutQueryParams = this.router.url.split('?')[0];
				console.log(data);
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
					this.router.navigate([urlWithoutQueryParams]);
				});
			},
			error => {
				console.log('Error:', error);
			}
			);
		}
	}

	ngAfterViewInit(): void {
		this.listenToPasteEvents();
	}
	listenToPasteEvents(): void {
		const container: HTMLElement = this.voucherOneplay.nativeElement;
		container.addEventListener('paste', this.handlePasteInOtpInputs.bind(this));
	}

	goBack() {
		this.location.back();
	}

	hmsToSecondsOnly(str: string): number {
		let p = str.split(':'),
			s = 0, m = 1;

		if (p.length < 3)
			p = `${str}:00`.split(':')

		while (p.length > 0) {
			s += m * parseInt(p.pop(), 10);
			m *= 60;
		}

		return s;
	}

	goToSeriePage(seriePath: string, assetPath: string): void {
		let genreFiterString = "?filter=" + encodeURI(JSON.stringify({ "path": seriePath }));
		this.assetGroupService.getAssetGroupDetails(genreFiterString).subscribe(res => {
			if (!res || !res.success) return;
			const seasonPaths = res.data[0].assetCategory.map(season => season.path);
			seasonPaths.forEach(seasonPath => {
				const assetGroupListingRequest = {
					"path": seasonPath,
					"type": "asset_category",
				};
				let assetGroupListingString = "?filter=" + encodeURI(JSON.stringify(assetGroupListingRequest));
				this.assetGroupService.getAssetListing(assetGroupListingString).subscribe(res => {
					if (res && res.success) {
						const assets = res.data;
						assets.forEach(asset => {
							if (assetPath == asset.path) {
								this.router.navigate(['/season', seriePath, seasonPath, assetPath], { replaceUrl: true });
								return;
							}
						})
					}
				});
			})
		})
	}

	seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {
		if (videoDuration) {
			if (setBookMark) {
				if ((this.playerObject.getDuration() - this.bookmark_duration) > 30) {
					this.playerObject.seek(this.playerObject.getCurrentTime() + this.bookmark_duration);
				} else {
					this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
				}
			}
		} else {
			this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
		}
	}

	nextVideo() {
		for (let relatedVideo of this.suggestedvideos) {
			if (relatedVideo.path != this.assetPath) {
				if (relatedVideo.type === 'asset') {
					this.router.navigate(['/video', relatedVideo.title.split(/\s/).join(''), relatedVideo.path]);
					break;
				} else if (relatedVideo.type === 'asset_group') {
					this.router.navigate(['/season', relatedVideo.path]);
				}
			}
		}
	}

	replayVideo() {
		this.seconds = 5;
		$('#premium-id').removeClass('premium-vod');
		this.replay = true;
		this.videoFinish = false;
		clearInterval(this.secondInterval);
		clearTimeout(this.secondsTimeout);
	}

	refreshHorizontalCarousel(carouselId: string): void {
		$('#' + carouselId).owlCarousel({
			loop: false,
			margin: 21,
			nav: true,
			navText: [
				'<img src="./assets/images/prev-arrow.svg">',
				'<img src="./assets/images/next-arrow.svg">'
			],
			autoplay: false,
			autoHeight: false,
			autoplayHoverPause: true,
			responsive: {
				0: {
					items: 2,
					nav: false
				},
				768: {
					items: 4
				},
				1000: {
					items: 6
				}
			}
		});
	}

	refreshVerticalCarousel(carouselId: string): void {
		$('#' + carouselId).owlCarousel({
			loop: false,
			margin: 13,
			nav: true,
			navText: [
				'<img src="./assets/images/prev-arrow.svg">',
				'<img src="./assets/images/next-arrow.svg">'
			],
			autoplay: false,
			autoHeight: false,
			autoplayHoverPause: true,
			responsive: {
				0: {
					items: 2,
					nav: false
				},
				768: {
					items: 3
				},
				1000: {
					items: 5
				}
			}
		});
	}

	public toggleFavourite(path: string, newValue?: boolean): void {
		let userId: any = this.userId;
		if (userId > 0) {
			if (this.geoStatus) {
				$('#favouriteloader').css({ "display": "block" });
				const pageRequest = { assetId: path };

				if (newValue === undefined)
					newValue = this.isUserLikes != 1;

				if (newValue) {
					this.videopageService.addTofavourite(pageRequest).subscribe(
						res => {
							if (res !== undefined && res.success === true) {
								this.isUserLikes = 1;
								this.successResponse(res.message);
								$('#favouriteloader').css({ "display": "none" });
							} else if (res !== undefined && res.success === false) {
								this.errMessageResponse(res.message);
							}
						},
						err => {
							console.log(err);
						}
					);
				} else {
					this.videopageService.removeTofavourite(pageRequest).subscribe(
						res => {
							if (res !== undefined && res.success === true) {
								this.isUserLikes = 0;
								this.successResponse(res.message);
								$('#favouriteloader').css({ "display": "none" });
							}
						},
						err => {
							console.log(err);
						}
					);
				}
			}
		} else {
			this.warningMessageResponse('Inicia sesión para agregarlo a tus favoritos');
		}
	}

	public addToWatchlist(): void {
		const pageRequest = {
			"userId": this.userId,
			"assetId": this.videopageData[0].path
		};
		this.videopageService.addToWatchlist(pageRequest).subscribe(
			res => {
				if (res !== undefined && res.success == true) {
					this.videopageData.watchedCount += 1;
				}
			},
			err => {
				console.log(err);
			}
		);
	}


	public getRelatedSuggestedVideos(): void {
		this.genrepath = [];
		this.primaryNavigatiopath = [];
		this.menuCategotypath = [];
		if (this.videopageData[0].genre.length > 0) {
			const genres = this.videopageData[0].genre
			for (let genre of genres) {
				this.genrepath.push(Number(genre.genreId));
			}
		}
		if (this.videopageData[0].primaryNavigation.length > 0) {
			const primaryNavigation = this.videopageData[0].primaryNavigation
			for (let navigation of primaryNavigation) {
				this.primaryNavigatiopath.push(Number(navigation.primaryNavigationId));
			}
		}
		if (this.videopageData[0].menuCategory.length > 0) {
			const menuCategory = this.videopageData[0].menuCategory
			for (let category of menuCategory) {
				this.menuCategotypath.push(Number(category.menuCategoryId));
			}
		}
		const pageRequest = {
			deviceTypeId: 1,
			genre: this.genrepath,
			languages: this.videopageData[0].langaugeId,
			primaryNavigation: this.primaryNavigatiopath,
			menuCategory: this.menuCategotypath
		};
		let filterString = "?filter=" + encodeURI(JSON.stringify(pageRequest)) + "&start=0&limit=15";
		this.videopageService.getRelatedVideo(filterString).subscribe(
			res => {
				if (res !== undefined && res.success === true) {
					this.relatedVideoData = null;
					res.data.data.forEach(asset => {
						// Set "streamsmas-content" in img paths
						asset.vodOrLivePosterImageFilePath = this.setTenantBucket(asset.vodOrLivePosterImageFilePath);
						asset.vodClosedCaptionFilePath = this.setTenantBucket(asset.vodClosedCaptionFilePath);
						asset.verticalFilePath = this.setTenantBucket(asset.verticalFilePath);
						asset.horizontalFilePath = this.setTenantBucket(asset.horizontalFilePath);

						// Set default duration if not populated
						if (asset.bookmarkDuration && !asset.duration) {
							asset.duration = 7200;
							asset.progressBarBookmarkWidth = ((asset.bookmarkDuration * 100) / asset.duration).toString() + "%"
						}
					});

					this.relatedVideoData = res.data.data.filter(v =>
						v.path != this.assetPath);
					setTimeout(() => {
						$('#recommended_carousel_list').trigger('destroy.owl.carousel');
						setTimeout(() => {
							this.refreshHorizontalCarousel('recommended_carousel_list');
						}, 50);
					}, 300);
					if (this.relatedVideoData.length == 0) {
						this.relatedVideoData = null;
						this.noRelatedData = true;
					}
				}
			},
			err => {
				this.noRelatedData = true;
				console.log(err);
			}
		);
	}

	public getCastCrew(assetpath: string): void {
		const pageRequest = { "assetId": assetpath };
		let fiterString = "?filter=" + encodeURI(JSON.stringify(pageRequest));
		this.videopageService.getCastCrews(fiterString).subscribe(
			res => {
				if (res !== undefined && res.success == true) {
					this.castcrewData = res.data;
					this.cast = '';
					this.director = '';
					if (this.castcrewData !== null && this.castcrewData.data) {
						for (const castcrew of this.castcrewData.data) {
							if (castcrew.type == 'Director') {
								if (this.director === '') {
									this.director = ' ' + this.director + castcrew.firstName + ' ' + castcrew.lastName;;
								} else {
									this.director = this.director + ', ' + castcrew.firstName + ' ' + castcrew.lastName;;
								}
							} else if (castcrew.type == 'cast') {
								if (this.cast === '') {
									this.cast = ' ' + this.cast + castcrew.firstName + ' ' + castcrew.lastName;
								} else {
									this.cast = this.cast + ', ' + castcrew.firstName + ' ' + castcrew.lastName;
								}
							}
						}
					}
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	public gotoVideoPage(pagedata: PageData): void {
		if (pagedata.type.toLowerCase() == 'asset') {
			this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
		} else if (pagedata.type.toLowerCase() == 'asset_group') {
			this.router.navigate(['/season', pagedata.path]);
		}
	}

	public getGenreTitle(videopageData: VideoPageData): string {
		var genreTitle = '';
		const genres = videopageData[0].genre
		if (genres.length > 0) {
			for (let orientation of genres) {
				if (genreTitle != '') {
					genreTitle += ', ';
				}
				genreTitle += orientation.genreName;
			}
		} else {
			this.noGenre = true;
		}
		this.genreTitle = genreTitle + ' ';
		return this.genreTitle;
	}

	public addToBookmark(assetDuration: string): void {
		const pageRequest = {
			assetId: this.videopageData[0].path,
			duration: assetDuration
		};
		this.videopageService.addToBookmark(pageRequest).subscribe(
			res => {
				if (res !== undefined && res.success == true) {
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	public goToHomePage(): void {
		this.headerService.reloadHeader.emit(true);
		this.router.navigate(['']);
	}

	gotoPlayerPage(pagedata) {
		if (this.getBrowserName() == 'edge') {
			this.warningMessageResponse('Para una mejor experiencia en su reproduccion, por favor utilizar el navegador Chrome');
			return
		}
		if (this.isLoggedIn) {
			if (this.isMobileDevice()) {
				$('#modal-web-or-app').modal('show');
				return;
			}
			if (pagedata.assetType.toLowerCase() == 'asset') {
				this.addToWatchlist();
				this.router.navigate(['/video-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
			} else if (pagedata.assetType.toLowerCase() == 'asset_group') {
				this.addToWatchlist();
				this.router.navigate(['/season', pagedata.path]);
			} else {
				this.addToWatchlist();
				this.router.navigate(['/video-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
			}
		} else {
			this.warningMessageResponse('Atención Debes iniciar sesión para poder reproducir este video');/*Inicia sesión para agregarlo a tus favoritos*/
		}
	}

	getBrowserName(): string {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf('edg') > -1:
				return 'edge';
			case agent.indexOf('opr') > -1 && !!(<any>window).opr:
				return 'opera';
			case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
				return 'chrome';
			case agent.indexOf('trident') > -1:
				return 'ie';
			case agent.indexOf('firefox') > -1:
				return 'firefox';
			case agent.indexOf('safari') > -1:
				return 'safari';
			default:
				return 'other';
		}
	}
	
	gotoTrailerPage(pagedata: VideoPageData): void {
		if (this.getBrowserName() == 'edge') {
			this.warningMessageResponse('Para una mejor experiencia en su reproduccion, por favor utilizar el navegador Chrome');
			return
		}
		this.router.navigate(['/trailer-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
	}

	goToPlansPage() {
		const removeAccents = (str) => {
			return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		}

		var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');

		var url_path_hidden = removeAccents('https://live.onstream.com.ar/video/' + this.videopageData[0].title.split(/\s/).join('') + '/' + this.videopageData[0].path);
		var client_id_hidden = this.user.id;
		var title_hidden = this.videopageData[0].title.split(/\s/).join('');
		var dni_hidden = dni[0];

		var html = '<form action="https://onstream.com.ar/payment-subscription" method="post" id="formHiddenPlans" style="display:none;"><input type="hidden"name="tenant_hidden" value="'+ this.tenant +'"><input type="hidden" name="payment_type_hidden" value="SUB"><input type="hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" name="unit_price_hidden" value="100"><button type="submit"></button></form>';
		$('#favouriteloader').css({ "display": "block" });
		$('#payment-model').append(html);
		$("#formHiddenPlans").submit();
	}

	async onClickExchangeAsset() {
		$("#confirmExchangeModal-assetName").text(this.videopageData[0].title);
		$("#payment-model").hide();
		$("#confirmExchangeModal").modal('show');
	}

	async onClickConfirmExchangeAsset() {
		$('#favouriteloader').css({ "display": "block" });

		try {
			// const { ip: publicIp } = await this.getPublicIp();
			const response = await fetch("https://api.oneplay.net/processes/onstreampromo/redeem.php", {
				method: "POST",
				body: JSON.stringify({
					tenant: this.tenant,
					userid: this.userId,
					assetid: this.assetPath,
					promo_code: this.promoCode,
					// ipaddress: publicIp,
					client_platform: "web"
				})
			})
			const data = await response.json();

			if (data.success === "1") {
				this.checkoutPromo(data.text);
			} else {
				$("#exchangeErrorModal-text").text(data.text);
				$("#exchangeErrorModal").modal("show");
				$('#favouriteloader').css({ "display": "none" });
			}
		} catch (error) {
			console.log(error)
		}
	}

	async checkoutPromo(successMessage) {
		let generated_hash = CryptoJS.SHA256(environment.voucherkey + this.assetPath + this.userId + this.videopageData[0].rate).toString(CryptoJS.enc.Hex);
		const orderReq2 = {
			orderId: generated_hash,
			assetId: this.assetPath,
			userId: this.userId,
			subscriptionId: this.subScriptionData[0].path,
			isRecurring: 1,
			billingName: this.user.firstName,
			billingEmail: this.user.emailId,
			status: "Active",
			usageType: "Paid",
			isActive: 1,
			amount: this.videopageData[0].rate,
			description: this.videopageData[0].title,
			paymentMethod: "vouchers"
		};

		this.videopageService.placeOrder(orderReq2).subscribe(
			res => {
				if (res !== undefined && res.success === true) {
					let now = new Date();

					now.setHours(now.getHours() + 48);

					let year = now.getFullYear();
					let month = now.getMonth() + 1;
					let day = now.getDate();
					let hour = now.getHours();
					let minute = now.getMinutes();

					let formattedDate = `${day}/${month}/${year} ${hour}:${minute}hs`;

					this.successResponse(
						"Todo listo, disfruta de " + this.videopageData[0].label +
						" hasta el " + formattedDate)
					// this.successResponse(successMessage
					// 	.replace(/<[^>]+>/g, ""));
					$('#favouriteloader').css({ "display": "none" });
					this.ngOnInit();
				} else if (res !== undefined && res.success === false) {
					this.errMessageResponse(res.message);
				}
			},
			err => {
				console.log(err);
			});
	}

	async checkExchangeAsset() {
		try {
			const response = await fetch("https://api.oneplay.net/processes/onstreampromo/getinfo.php", {
				method: "POST",
				body: JSON.stringify({
					userid: this.userId,
					tenant: this.tenant,
					assetid: this.assetPath
				})
			});
			const data = await response.json();

			this.promoCode = data.promo_code ? data.promo_code : null;
			this.remainingSwaps = data.left;
			$("#rentModal-text").text(data.text);
			$("#rentModal-exchangeButton").text(data.button);
			if (data.success === "1" && data.left >= 1) {
				$("#rentModal-exchangeButton").show();
			} else {
				$("#rentModal-exchangeButton").hide();
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getPublicIp() {
		const response = await fetch("https://api.ipify.org/?format=json", {
			method: "GET",
		});
		return await response.json();
	}

	async goRent(event, type) {
		if (this.isLoggedIn) {
			if (type == 'TVOD') {
				$('#favouriteloader').css('display', 'block');
				if (!this.subScriptionData.length) {
					await this.getPremiumForSubscriptionList();
				}
				await this.checkExchangeAsset();
				$('#favouriteloader').css('display', 'none');
				$('#payment-model').modal('show');
			} else if (type == 'SVOD') {
				var explode_dni = this.user.emailId;
				var dni = explode_dni.split('@');

				var tenant_hidden = this.tenant;
				var payment_type_hidden = 'SUB';
				var url_path_hidden = 'https://live.onstream.com.ar/';
				var client_id_hidden = this.user.id;
				var title_hidden = 'sidebar';
				var dni_hidden = dni[0];
				var unit_price_hidden = 100;

				var html = '<form action="https://onstream.com.ar/payment-subscription" method="post" id="formHiddenPlans" style="display:none;"><input type="hidden" id="tenant_hidden" name="tenant_hidden" value="' + tenant_hidden + '"><input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="' + payment_type_hidden + '"><input type="hidden" id="url_path_hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" id="client_id_hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" id="title_hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" id="dni_hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" id="unit_price_hidden" name="unit_price_hidden" value="' + unit_price_hidden + '"><button type="submit">ADQUIRIR</button></form>';
				$("#plansFormContainer").append(html);
				setTimeout(function () {
					$("#formHiddenPlans").submit();
				}, 300);

			}
		} else {
			localStorage.setItem('videoUrl', this.router.url)
			this.warningMessageResponse('Inicia sesión para ver este contenido');
		}
	}

	paymentmethod(event, type) {
		if (type == 'stripe') {
			$('#rent-list').modal('show');
		} else if (type == 'voucher') {
			$('#modal-vouchers').modal('show');
			this.checkout2(this.subScriptionData[0], event)
		} else if (type == 'mercadopago') {
			$('.main-loader-overlay').css('display', 'none');
			$('#favouriteloader').css('display', 'none');
		}
	}

	paymentmethodStripeCupon(event, type) {
		if (type == 'cupon') {
			this.checkout4(this.subScriptionData[0], event);
		}
	}

	getPremiumForSubscriptionList() {
		return new Promise((resolve, reject) => {
			this.subScriptionData = [];
			const subscriptionRequest = { "deviceTypeId": "1", "assetId": this.assetPath };
			const subscriptionFiterString = "?filter=" + encodeURI(JSON.stringify(subscriptionRequest));
			this.assetGroupService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
				if (res != undefined && res.success == true) {
					const rentPlan = res.data.data;
					for (let plan of rentPlan) {
						if (plan.subscriptionType == 'TVOD') {
							this.subScriptionData.push(plan);
						}
					}
					resolve("ok");
				}
			})
		})
	}

	checkout(item, e) {
		this.handler = (<any>window).StripeCheckout.configure(
			{
				key: environment.stripeKey,
				locale: "auto",
				token: (token: any) => {
					let cardToken = token.id;
					const orderReq = {
						orderId: cardToken,
						assetId: this.assetPath,
						userId: this.userId,
						subscriptionId: item.path,
						isRecurring: 1,
						billingName: this.user.firstName,
						billingEmail: this.user.emailId,
						status: "Active",
						usageType: "Paid",
						isActive: 1,
						paymentMethod: "stripe"
					};
					this.videopageService.placeOrder(orderReq).subscribe(
						res => {
							if (res !== undefined && res.success === true) {
								this.successResponse(res.message);
								$('#favouriteloader').css({ "display": "none" });
								$('#rent-list').modal('hide');
								this.ngOnInit();
							} else if (res !== undefined && res.success === false) {
								this.errMessageResponse(res.message);
							}
						},
						err => {
							console.log(err);
						}
					);
				},
			}
		);
		let totalAmount = item.rate * 100;
		if (totalAmount > 0) {
			this.handler.open({
				name: "ON STREAM",
				description: item.title,
				amount: totalAmount,
				email: this.user.emailId,
				closed: function () {
					$('#favouriteloader').css({ "display": "none" });
				}
			});
		}
		e.preventDefault();
	}

	closeloader() {
		$('#favouriteloader').css({ "display": "none" });
	}

	checkout2(item, e) {
		let totalAmount = item.rate.toString();
		let totalAmountDecimal = totalAmount.replace(',', '.');

		//en pagina de checkout, al momento de checkout
		//paso 1: le digo a libreria que haga render en el div
		(<any>window).OP.renderFormInElement("voucher-oneplay");
		//paso 2: inicio intent de redeem y paso parametros obligatorios
		(<any>window).OP.openRedeemVoucherIntent({
			client_email: this.user.emailId,
			client_country: this.user.countryCode, //this is the value of the client`s registration on database 
			asset_value: totalAmountDecimal,
			asset_id: this.assetPath, //this code could be either asset id value or the monetization plan id value
			client_id: this.userId,
			client_platform: "web", //this is identify the platform, values: web,ios,android
		})
			.then(res => {
				const orderReq2 = {
					orderId: res.data.hash,
					assetId: this.assetPath,
					userId: this.userId,
					subscriptionId: item.path,
					isRecurring: 1,
					billingName: this.user.firstName,
					billingEmail: this.user.emailId,
					status: "Active",
					usageType: "Paid",
					isActive: 1,
					amount: item.rate,
					description: item.title,
					paymentMethod: "vouchers"
				};
				let generated_hash = CryptoJS.SHA256(environment.voucherkey + this.assetPath + this.userId + totalAmountDecimal).toString(CryptoJS.enc.Hex);
				if (generated_hash == res.data.hash) {
					this.videopageService.placeOrder(orderReq2).subscribe(
						apires => {
							if (apires !== undefined && apires.success === true) {
								let now = new Date();

								now.setHours(now.getHours() + 48);

								let year = now.getFullYear();
								let month = now.getMonth() + 1;
								let day = now.getDate();
								let hour = now.getHours();
								let minute = now.getMinutes();

								let formattedDate = `${day}/${month}/${year} ${hour}:${minute}hs`;

								this.successResponse(
									"Todo listo, disfruta de " + this.videopageData[0].label +
									" hasta el " + formattedDate)
								// this.successResponse(res.message);
								$('#favouriteloader').css({ "display": "none" });
								this.ngOnInit();
							} else if (apires !== undefined && apires.success === false) {
								this.errMessageResponse(apires.message);
							}
						},
						err => {
							console.log(err);
						});
				}
				$('#modal-vouchers').modal('hide');
			})
			.catch(err => {
				$('#modal-vouchers').modal('hide');
				console.log(err)
				this.errMessageResponse(err.message)
			})
		$('#modal-vouchers').modal('show');
		e.preventDefault();
	}

	checkout4(item, e) {
		$('#favouriteloader').css({ "display": "block" });
		let totalAmount_mp = item.rate.toString();

		var explode_dni = this.user.emailId;
		var dni = explode_dni.split('@');

		var tenant_hidden = this.tenant;
		var payment_type_hidden = 'ALQ';
		var url_path_hidden = 'https://live.onstream.com.ar/video/' + this.videopageData[0].title.split(/\s/).join('') + '/' + this.videopageData[0].path;
		var client_id_hidden = this.user.id;
		var title_hidden = item.title;
		var dni_hidden = dni[0];
		var unit_price_hidden = totalAmount_mp;

		var html = '<form action="https://onstream.com.ar/payment-tvod" method="post" id="formHidden" style="display:none;"><input type="hidden" id="tenant_hidden" name="tenant_hidden" value="' + tenant_hidden + '"><input type="hidden" id="payment_type_hidden" name="payment_type_hidden" value="' + payment_type_hidden + '"><input type="hidden" id="url_path_hidden" name="url_path_hidden" value="' + url_path_hidden + '"><input type="hidden" id="client_id_hidden" name="client_id_hidden" value="' + client_id_hidden + '"><input type="hidden" id="title_hidden" name="title_hidden" value="' + title_hidden + '"><input type="hidden" id="dni_hidden" name="dni_hidden" value="' + dni_hidden + '"><input type="hidden" id="unit_price_hidden" name="unit_price_hidden" value="' + unit_price_hidden + '"><button type="submit">TARJETA</button></form>';
		$("#container-mercadopagobtn").append(html);
		$("#formHidden").submit();
	}

	handlePasteInOtpInputs(event: ClipboardEvent): void {
		event.preventDefault();
		const container: HTMLElement = this.voucherOneplay.nativeElement;
		const clipboardData = event.clipboardData ? event.clipboardData.getData('text') : '';
		if (!clipboardData) {
			return;
		}
		const chars = clipboardData.split('');
		for (let i = 0; i < chars.length; i++) {
			const inputElement = container.querySelector(`#otp-field${i}`) as HTMLInputElement;
			if (inputElement) {
				inputElement.value = chars[i];
			}
		}
	}

	openApp() {
		const iOSAppStoreURL = 'https://apps.apple.com/bo/app/onstream/id6449293113';
		const androidPlayStoreURL = 'https://play.google.com/store/apps/details?id=com.oneplay.axion';

		const userAgent = window.navigator.userAgent;
		const isAndroid = userAgent.includes('Android');
		const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window['MSStream'];
	  
		if (isiOS) {
			// const appURLScheme = 'com.oneplay.filmity://' + this.router.url;
			// window.location.href = appURLScheme;
			setTimeout(() => {
				if (document.hasFocus())
					window.location.href = iOSAppStoreURL;
			}, 500);
		} else if (isAndroid) {
			// const appURLScheme = 'https://live.filmity.com/' + this.router.url;
			// window.location.href = appURLScheme;
			setTimeout(() => {
				window.location.href = androidPlayStoreURL;
			}, 500);
		}
	}

	playInWeb() {
		const pageData = this.videopageData[0];
		if (pageData.assetType.toLowerCase() == 'asset') {
			this.router.navigate(['/video-page', pageData.title.split(/\s/).join(''), pageData.path]);
		} else if (pageData.assetType.toLowerCase() == 'asset_group') {
			this.router.navigate(['/season', pageData.path]);
		} else {
			this.router.navigate(['/video-page', pageData.title.split(/\s/).join(''), pageData.path]);
		}
	}

	ngOnDestroy() {
		clearTimeout(this.secondsTimeout);
		clearInterval(this.secondInterval);
		// if (this.playerObject !== undefined) {
			// this.playerObject.pause();
			// this.playerObject.unload();
		// }
	}
}
