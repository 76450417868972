import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenresListingComponent } from '../component/genres-listing.component';
import { RouterModule } from '@angular/router';
import { LoaderModule } from 'src/app/components/loader/loader.module';

@NgModule({
    declarations: [GenresListingComponent],
    imports: [CommonModule, RouterModule, LoaderModule],
    exports: [GenresListingComponent],
    providers: []
})

export class GenresListingModule {

}
