import { baseRequest } from "./api";
import { API_REFERENCE } from '../base/constants/base.constants';
import { BaseComponent } from '../base/component/base.component';
import { CheckEmailExistsProps, LoginProps, SetNewPasswordProps } from "./schemas";

export const getGuestToken = async () => {
    const { data: token } = await baseRequest({ url: API_REFERENCE["user"]["usertoken"] });
    BaseComponent.setGuestToken(token);
};

export const checkEmailExists = async (body: CheckEmailExistsProps) => {
    return await baseRequest({
        url: API_REFERENCE["user"]["checkuser"],
        method: "POST",
        body,
    });
};

export const logIn = async (body: LoginProps) => {
    return await baseRequest({
        url: API_REFERENCE["user"]["login"],
        method: "POST",
        body
    });
};

export const logOut = async () => {
    return await baseRequest({
        url: API_REFERENCE["user"]["logout"],
        method: "POST",
    });
};

export const forgotPassword = async (body: { emailId: string }) => {
    return await baseRequest({
        url: API_REFERENCE["user"]["forgotPassword"],
        method: "POST",
        body
    });
};

export const setNewPassword = async (body: SetNewPasswordProps) => {
    return await baseRequest({
        url: API_REFERENCE["user"]["updatePassword"],
        method: "POST",
        body
    })
}