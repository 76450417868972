import { environment } from '@environments/environment';
import { BaseComponent } from '../base/component/base.component';
import { ApiService } from '@base/services/base.api.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { HttpHeaders } from '@angular/common/http';

export interface BaseRequestProps {
    method?: "GET" | "POST" | "PUT" | "DELETE";
    url: string;
    body?: { [key: string]: any } | string;
    headers?: HttpHeaders,
    showError?: boolean
}

export const baseRequest = async ({ method = "GET", url, body, showError = true }: BaseRequestProps) => {
    if (method === "GET")
        url = noCacheUrl(url);
    
    const token = BaseComponent.token;
    const requestOptions: BaseRequestProps = {
        url: `${environment.apiUrl}${url}`,
        method,
        headers: new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(token && {
                Authorization: token,
            }),
        }),
        ...(body && ({ body: JSON.stringify(body) })),
    };

    try {
        const apiService = ServiceLocator.injector.get(ApiService);
        const response = await apiService.newApiCall(requestOptions);
        const decodedResponse = decodeJWT(response.body);
        if (showError) checkErrorAndShowTooltip(response, decodedResponse);
        return decodedResponse;
    } catch (err) {
        if (showError) checkErrorAndShowTooltip(err);
    }
}

const checkErrorAndShowTooltip = (response, decodedResponse = null) => {
    if (!decodedResponse)
        decodedResponse = decodeJWT(response.error);

    if (response.status >= 400 || !decodedResponse.success) {
        new BaseComponent().errMessageResponse(decodedResponse.message || "Hubo un error.");
        throw new Error(response.message);
    }
}

const noCacheUrl = (url: string) => {
    const sign = url.includes("?") ? "&" : "?";
    const noCache = `noCache=${Math.floor(Math.random() * 99)}`;
    return `${url}${sign}${noCache}`;
}

export const decodeJWT = (str: string) => {
    const base64Url = str.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((c) => ('%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)))
        .join(''));
    return JSON.parse(jsonPayload);
}